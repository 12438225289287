
import { IonContent, IonPage, IonButton, IonLabel, IonGrid, IonRow, IonCol, IonLoading } from '@ionic/vue'
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '@/modules/tickets/business'
import EventCardComponent from '@/modules/events/presentation/components/EventCardComponent.vue'
import CoreValues from '../components/CoreValuesComponent.vue'
import 'vue3-carousel/dist/carousel.css';

import { logoInstagram, logoFacebook, logoSoundcloud, logoTwitch, logoWhatsapp, cloudDownloadOutline } from "ionicons/icons";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { VuePdf, createLoadingTask } from 'vue3-pdfjs/esm';

import StepProgressBar from '../components/StepProgressBarComponent.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import { ContentTypes } from '../../enums'

export default defineComponent({
name: 'ContentPage',
  components: {
    IonContent,
    IonPage,
    // IonButton,
    QuillEditor,
    // VuePdf,
    StepProgressBar,
    Swiper,
    SwiperSlide,
    Splitpanes, 
    Pane,
    ButtonLoading,
    IonLoading,
    // StepProgress,
    VuePdf
  },
  data() {
    return {
      hashId: this.$router.currentRoute.value.params.hashId,
      viewContent: ['','','',''],
      numOfPages: 10,
      currentSlide: this.$route.query.slide,
      swiper: null,
      loaderShown: true,
      isLoadingConfirm: false,
      ContentTypes
    }
  },
  computed: {
    ...mapGetters('internal',{
      edit: 'EDIT',
    }),
     ...mapGetters('tickets',{
      content: GettersTypes.content,
      courses: GettersTypes.courses
    }),
    
  },
  methods: {
    ...mapActions('tickets', {
      saveContent: ActionTypes.saveContent,
      getContent: ActionTypes.getContent,
      getLectures: ActionTypes.getLectures
    }),
    onSwiper(swiper) {
      this.swiper = swiper
      // if (this.edit) {
      //   this.swiper.enabled = false
      // }
    },
    changeStep(index) {
      this.currentSlide = index
      this.$router.push({path: this.$route.path, query: {slide: index} });
      try {
        this.swiper.slideTo(index)
      } catch {
        console.log('Swiper off while editing')
      }
    },
    addPage(icon) {
      this.content.push({title: ContentTypes[icon], icon: icon, text: ''})
      this.currentSlide = this.content.length - 1
    },
    removePage(index) {
      this.content.splice(index, 1)
      this.currentSlide = this.content.length - 1
    },
    async save() {
      this.isLoadingConfirm = true
      await this.saveContent({auth: this.$cookies.get('access_token'), content: this.content, hashId: this.hashId})
      this.isLoadingConfirm = false
    },
    nextLecture() {
      var json = this.courses
      if (json) {
        const lectures = json.reduce((acc, course) => {
          return acc.concat(course.lectures);
        }, []);
        const hashIds = lectures.map(lecture => lecture.hashId);
        
        const index = hashIds.indexOf(this.hashId)
        if ( hashIds[index + 1]) {
          this.$router.push({path: '/lekce/' +  hashIds[index + 1]})
        } else {
          this.$router.push({path: '/kurzy'})
        }
      }
    },
    getPDF(name) {
      return process.env.VUE_APP_ASSETS_URL + '/lectures/' + name
    },
    getSwiperHeight() {
      var height = document.querySelector('.swiper-slide-active')?.clientHeight
      var element = document.querySelector('.swiper-wrapper') as HTMLElement
      element.style.height = height + 'px'
    }
  },
  setup() {
    const numOfPages = ref(0)


    return {
      logoInstagram,
      logoFacebook,
      logoSoundcloud,
      logoTwitch,
      logoWhatsapp,
      cloudDownloadOutline
    }
  },
  async mounted() {
    this.loaderShown = true
    await this.getContent({hashId: this.hashId, auth: this.$cookies.get('access_token')})
    this.loaderShown = false
    this.courses == undefined && await this.getLectures(this.$cookies.get('access_token'))
    this.changeStep(this.currentSlide)
  },
  watch: {
    'swiper.activeIndex'() {
      if (!this.edit && !this.$route.query.slide) {
        this.currentSlide = this.swiper.activeIndex
      }
      this.getSwiperHeight()
    },
    content: {
      handler(val, oldVal) {
        this.content?.forEach((el, i) => {
          this.viewContent[i] = el.text?.replaceAll('&lt;','<').replaceAll('&gt;','>')
        });
        setTimeout(() => {
          this.getSwiperHeight()
        }, 200);
        
      },
      deep: true
    },
    // '$route': {
    //   async handler(to, from) {
    //     this.loaderShown = true
    //     await this.getContent({hashId: this.hashId, auth: this.$cookies.get('access_token')})
    //     this.loaderShown = false
    //     this.courses == undefined && this.getLectures(this.$cookies.get('access_token'))
    //   }, deep: true
    // }
  }
});
