
import { IonPage, IonContent, IonList, IonItem, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import BlogPost from '../components/BlogPostComponent.vue'
import 'vue3-carousel/dist/carousel.css';
import { logoInstagram, logoFacebook, logoSoundcloud, logoTwitch, logoWhatsapp, mic, chatbubblesOutline, bulbOutline, rocketOutline, addCircleOutline } from "ionicons/icons";


export default defineComponent({
name: 'BlogPage',
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    BlogPost
  },
  data() {
    return {
      accordionOpen: false,
      posts: undefined,
      batch: 1,
      loading: false,
      addNewPost: false,
    }
  },
  computed: {
    ...mapGetters('blog',{
      blog: GettersTypes.blog,
      available: GettersTypes.available
    }),
    ...mapGetters({
      auth: 'AUTH'
    }),
  },
  methods: {
    ...mapActions('blog',{
      getBlogBatch: ActionTypes.getBlogBatch,
      getBlog: ActionTypes.getBlog,
    }),
    async loadData(ev) {
      this.showSkeleton = true
      this.batch += 1
      await this.getBlogBatch(this.batch)
      setTimeout(() => {
        ev.target.complete();
        this.showSkeleton = false
      }, 1000)
    },
    async refreshBlog() {
      this.posts = undefined
      this.batch = 1
      await this.getBlogBatch(1)
      this.addNewPost = false
    }
  },
   setup() {
    const isDisabled = ref(false);
    
    return {
      isDisabled,      
      addCircleOutline
    }
    
  },
  mounted() {
    this.getBlogBatch(this.batch)
  },
  watch: {
    blog() {
      if (this.posts) {
        this.posts = this.posts.concat(this.blog)
      } else {
        this.posts = this.blog
      }
    } 
  }
});
