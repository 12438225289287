import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createTextVNode(" Skrýt "),
          _createVNode(_component_ion_toggle, {
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}