
import { IonApp, IonRouterOutlet, toastController, IonToast, IonButton, IonIcon, IonFab, IonFabButton } from '@ionic/vue';
import { cartOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import FabMenu from '@/modules/common/components/FabMenuComponent.vue'
import { mapGetters, mapActions } from 'vuex'
import { Roles } from '@/modules/auth/enums'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    FabMenu,
    IonFab, 
    IonFabButton
    // IonToast,
    // IonButton,
    // IonIcon
  },
  data() {
    return {
      isMenuOpen: false,
      routerClick: true,
      closeFab: false,
      authorizedPaths: [this.$t('toolbar.path_tickets'), this.$t('toolbar.path_account'), this.$t('toolbar.path_scanner')],
      internalPaths: [this.$t('toolbar.path_scanner')],
      cookiesMore: false,
      CookieToast: undefined,
      basketToast: undefined

    }
  },
  setup() {
    return {
      cartOutline
    }
  },
  computed: {
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED'
    }),
    ...mapGetters('checkout',{
      basket: 'BASKET'
    })
  },
  methods: {
    ...mapActions({
      setToken: 'SET_TOKEN',
      verifyToken: 'VERIFY_TOKEN'
    }),
    async authorizedPages() {
      var currentRoute = this.$router.currentRoute.value.path
      currentRoute = currentRoute.slice(-1) == '/' ? currentRoute.slice(0,-1) : currentRoute
      await this.verifyToken(this.auth.token)
      if (!this.isAuthorized && this.authorizedPaths.includes(currentRoute)) {
        this.$router.push({ path: this.$t('toolbar.path_signin'), query: {redirect: this.$route.fullPath} })
      }
      if (this.isAuthorized && this.internalPaths.includes(currentRoute)) {
        if (this.auth.role == Roles.USER) {
          this.$router.push({ path: this.$t('toolbar.path_portfolio') })
        }
      }
    },
    routerClicked() {
      if (this.isMenuOpen) {
       this.closeFab = true
        this.isMenuOpen = false
        setTimeout(() => {
          this.closeFab = false
        }, 50) 
      }
    },
    async presentToast() {
        this.CookieToast = await toastController.create({
          header: 'Cookies 🍪',
          message: 'Na tvém soukromí nám záleží',
          buttons: [
            {
              text: 'Více',
              role: 'cancel',
              handler: () => {
                this.presentToastMore();
              }
            },
            {
              text: 'Příjmout',
              role: 'submit',
              icon: 'checkmark-outline',
              handler: () => {
                this.$cookies.set('cookies',true)
              }
            }
          ],
        });

        await this.CookieToast.present();
      },
    async presentToastMore() {
        const toast = await toastController.create({
          header: 'Více informací 🍪',
          message: 'Na tvém soukromí nám záleží! Sbíráme proto pouze cookies nezbytné pro provoz webu. Díky tomu si web pamatuje tvé uživatelské údaje nebo tvůj progres v kurzech. Tvé údaje nejsou využívány k marketingovým účelům ani předávány třetím stranám.',
          buttons: [
            {
              text: 'Odmítnout',
              role: 'cancel',
              handler: () => {
                this.$cookies.set('cookies',false)
              }
            },
            {
              text: 'Příjmout',
              role: 'submit',
              icon: 'checkmark-outline',
              handler: () => {
                this.$cookies.set('cookies',true)
              }
            }
          ],
        });

        await toast.present();
      },
      async presentToastBasket() {
        var basketToast = await toastController.create({
          header: 'Přidáno do košíku ✅',
          message: 'Klikněte pro zobrazení objednávky.',
          buttons: [
            {
              text: 'Košík',
              role: 'submit',
              icon: cartOutline,
              handler: () => {
                this.isAuthorized ? this.$router.push({path: this.$t('toolbar.path_checkout')}) : this.$router.push({path: this.$t('toolbar.path_signup')})
              }
            }
          ],
        });

        
        basketToast.present();
        setInterval(() => {
          basketToast.dismiss();
        }, 2000);
      },
  },
  watch: {
    'auth.token': function() {
      if (this.auth.token) {
        this.$cookies.set('access_token',this.auth.token)
        this.CookieToast?.dismiss()
      } else {
        this.$cookies.remove('access_token')
      }
    },
    isAuthorized() {
      if (this.isAuthorized) {
        this.setToken({token: this.auth.token, role: this.auth.role, email: this.auth.email}) 
      }
      this.authorizedPages()
    },
    $route (to, from){
      this.authorizedPages()
      if (to.path == '/sign-up' && from.path == '/sign-in' && this.isAuthorized) {
        this.$router.push({ path:'/events' })
      } else if (to.path == '/sign-in' && from.path == '/sign-up' && this.isAuthorized) {
        this.$router.push({ path:'/events' })
      } else if (to.path == '/forgotten-password' && from.path == '/sign-in') {
        this.$router.push({ path:'/events' })
      }
    },
    basket: {
      handler(to, from) {
        // this.presentToastBasket()
        // setInterval(() => {
        //   this.basketToast.dismiss();
        // }, 2000);
      },
      deep: true
    }
  },
  async mounted() {
    var token = this.$cookies.get('access_token')
    if (token) {
      await this.verifyToken(token)
    }
    if (this.$cookies.get('cookies')===null && this.$cookies.get('access_token')===null && !this.$route.meta.hideToolbar) {
      this.presentToast()
    }
    // this.authorizedPages()
    
  }
});
