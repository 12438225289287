
import { IonPage, IonItem, IonLabel, IonInput, IonLoading, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { Site } from '../../enums'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import UserExistsModal from '../components/modal/UserExistsModal.vue'


export default defineComponent({
name: 'UserDetailsPage',
  components: {
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonLoading,
    ButtonLoading
  },
  data() {
    return {
      loaderShown: false,
      submitted: false,
      nameDanger: "",
      surnameDanger: "",
      documentDanger: "",
      buttonDanger: "light",
      name: "",
      surname: "",
      document: "",
      phone: "",
      Site
    }
  },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized,
      userExists: GettersTypes.userExists,
    }),
  },
  methods: {
    ...mapActions({
      userDetails: ActionTypes.userDetails,
      checkEmail: ActionTypes.checkEmail,
      getAccountInfo: ActionTypes.getAccountInfo,
      confirmPayment: 'CONFIRM_PAYMENT',
    }),
    async submit() {
      this.submitted = true
      const validInput = this.validateInput()
      if (validInput) {
        this.loaderShown = true
        await this.userDetails({token: this.$cookies.get('access_token'), name: this.name, surname: this.surname, document: this.document, phone: this.phone})
        this.loaderShown = false
        if (!this.userExists) {
          this.$emit('signedUp')
          this.getAccountInfo(this.$cookies.get('access_token'))
          this.$router.push({ path: this.$t('toolbar.path_tickets') })
        }
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validateInput() {
      if (this.submitted) {
        this.nameDanger = this.name ? '' : 'danger'
        this.surnameDanger = this.surname ? '' : 'danger'
        this.documentDanger = this.document ? '' : 'danger'
        this.buttonDanger = ( this.name && this.surname && this.document)  ? 'light' : 'danger'
        return (this.name && this.surname && this.document)
      }
    },
    checkExistingEmail() {
      if (this.email) {
        this.checkEmail(this.email)
      }
    },
    async openUserExistsModal() {
      const modal = await modalController.create({
        component: UserExistsModal,
        componentProps: {prefilledEmail: this.email, site: this.userExists}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      // if (role === 'confirm') {
      //   this.$emit('showLoader')
      // }
    },
  },
  watch: {
    userExists() {
      if (this.userExists) {
        this.openUserExistsModal()
      }
    }
  },
});
