<script>
import { IonButton, IonIcon, IonBadge } from '@ionic/vue'
import { defineComponent } from 'vue';
import { addOutline, logoYoutube, bookOutline, headsetOutline, pencilOutline, trophyOutline } from 'ionicons/icons';
import IconPicker from './IconPickerComponent.vue'

export default defineComponent({
  name: 'StepProgressBar',
  components: {
    IonButton,
    IonIcon,
    IconPicker,
    IonBadge
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    edit: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      iconPickerOpen: false
    }
  },
  methods: {
    goToStep(stepIndex) {
      if (stepIndex <= this.currentStep) {
        this.$emit('update:currentStep', stepIndex);
      }
    },
    getIcon(item) {
      let icons = {
        addOutline: addOutline,
        logoYoutube: logoYoutube,
        bookOutline: bookOutline,
        headsetOutline: headsetOutline,
        pencilOutline: pencilOutline,
        trophyOutline: trophyOutline,
      };
      if (item in icons) return icons[item];
    },
    addSlide(icon) {
      this.$emit('add', icon)
      this.iconPickerOpen = false
    }
  },
  setup() {
    return {
      addOutline,
      logoYoutube,
      bookOutline, 
      headsetOutline, 
      pencilOutline,
      trophyOutline
    }
  },
});
</script>

<template>
<div class="stepper-container">
  <div class="stepper">
    <div v-for="(step, index) in steps" :key="index" class="step-container" >
      <div
        class="step"
        :class="{ selected: index === currentStep, completed: index < currentStep }"
        @click="goToStep(index)"
      >
        <div class="button-container">
          <ion-button 
            v-if="edit"
            color="none" 
            class="step-button" 
            :class="(index == currentStep  ? 'medium' : 'transparent') + ' step-button-'+index"
            shape="circle" 
            mode="ios"
            @click="$emit('index', index)"
          >
            <ion-icon slot="icon-only" :icon="getIcon(step.icon)"></ion-icon>
          </ion-button>
          <ion-button 
            v-else
            color="none" 
            class="step-button" 
            :class="index <= currentStep  ? 'medium' : 'transparent'"
            :style="index < currentStep  && {backgroundColor: 'var(--ion-color-success-shade)'}"
            shape="circle" 
            mode="ios"
            @click="$emit('index', index)"
          >
            <ion-icon slot="icon-only" :icon="getIcon(step.icon)"></ion-icon>
          </ion-button>
          <ion-badge 
            class="remove-badge"
            :class="'edit-button-'+index"
            color="danger"
            @click="$emit('remove', index)"
          >
            -
          </ion-badge>
          {{ step.title }}
        </div>
        <hr class="line" :class="(!edit && index < currentStep - 1) && 'completed'">
      </div>
    </div>
    
    <div v-if="edit" class="step-container">
      <div class="step">
        <hr class="line" />
        <ion-button 
          color="none" 
          class="step-button transparent"
          style="border: dashed;"
          shape="circle"
          mode="ios"
          @click="iconPickerOpen = !iconPickerOpen"
        >
          <ion-icon slot="icon-only" :icon="addOutline"></ion-icon>
        </ion-button>
        <IconPicker :open="iconPickerOpen" @icon="addSlide"/>
      </div>
      {{'Přidat'}}
    </div>
    <div v-else-if="steps" class="step-container">
      <div class="step">
        <hr class="line" />
        <ion-button 
          color="none" 
          class="step-button transparent"
          style="border: dashed;"
          shape="circle"
          mode="ios"
          @click="$emit('index', steps.length + 1)"
        >
          <ion-icon slot="icon-only" :icon="trophyOutline"></ion-icon>
        </ion-button>
      </div>
      
      {{'Fertig!'}}
      
    </div>
  </div>
</div>
</template>

<style scoped>

.step-button-0:hover ~ .edit-button-0,
.step-button-1:hover ~ .edit-button-1,
.step-button-2:hover ~ .edit-button-2,
.step-button-3:hover ~ .edit-button-3,
.step-button-4:hover ~ .edit-button-4,
.step-button-5:hover ~ .edit-button-5,
.step-button-6:hover ~ .edit-button-6,
.step-button-7:hover ~ .edit-button-7,
.step-button-8:hover ~ .edit-button-8,
.step-button-9:hover ~ .edit-button-9 {
  display: block;
}

.remove-badge:hover {
  display: block
}

.remove-badge {
  display: none;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  position: absolute;
  margin-left: 40px;
  cursor: pointer;
}

.button-container {
  display: flex;
  flex-direction: column;
}

ion-button {
    margin: 5px;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: visible!important;
}

.stepper-container {
  overflow: scroll;
  text-align: center;
}

.stepper {
  display: -webkit-inline-box;
  color: black;
  justify-content: center;
  padding: 5px 10px;
  
}


.step {
  display: flex;
  align-items: center;
}

.step-button {

  
}

ion-icon {

}

ion-button[shape=circle] {
    --border-radius: 100px;
    width: 50px;
    height: 50px;
    padding:0
}

.step-container {
  text-align: center;
  overflow-x: scroll
}

.step-container:last-child div .line {
  display: none;
}

.step  .line {
  width: 60px; 
  border-top: 1px solid black;
  margin: 0;
  margin-top: -18px;
  opacity: 0.2;
}

.step  .line.completed {
  border-color: green;
}

.step .icon {
  font-size: 24px;
  border-radius: 50%;
  border: 2px solid black;
  padding: 8px;
  margin-bottom: 8px;
}

.step.selected .icon {
  background-color: black;
  color: var(--ion-color-secondary-shade);
}

.step.completed .icon {
  border-color: green;
}

@media (max-width: 780px) { 
  .step  .line {
    width: 20px; 
  }
  
}

</style>
