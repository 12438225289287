import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (!_ctx.edit)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "badge",
        style: _normalizeStyle('background-color: var(' + this.LevelColor[_ctx.preset] + ')')
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.Level[_ctx.preset]), 1)
      ], 4))
    : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_select, {
                modelValue: _ctx.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
                interface: "popover"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select_option, { value: "A1" }, {
                    default: _withCtx(() => [
                      _createTextVNode("A1")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select_option, { value: "A2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("A2")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select_option, { value: "B1" }, {
                    default: _withCtx(() => [
                      _createTextVNode("B1")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select_option, { value: "B2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("B2")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select_option, { value: "C1" }, {
                    default: _withCtx(() => [
                      _createTextVNode("C1")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select_option, { value: "C2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("C2")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}