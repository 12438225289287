
import { 
  IonList,
  IonItem, 
  IonText, 
  IonTitle,
  IonAccordionGroup, 
  IonAccordion,
  IonIcon,
  toastController
} from '@ionic/vue'
import { chevronForwardOutline, chevronDownOutline, cartOutline } from "ionicons/icons";
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { ActionTypes } from '../../business'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'


export default defineComponent({
name: 'LecturesList',
  components: {
    IonList,
    IonItem, 
    IonText, 
    IonTitle,
    IonAccordionGroup, 
    IonAccordion,
    ButtonLoading,
    IonIcon, 
  },
  props: {
    event: {
      type: Object
    }
  },
  data() {
    return {
      loaderShown: false,
      submitted: false,
      emailColor: "",
      instagramColor: "",
      buttonColor: "light",
      email: "",
      instagram: "",
      noInstagram: false
    }
  },
  setup() {
    return {
      chevronForwardOutline, chevronDownOutline, cartOutline
    };
  },
  computed: {
    ...mapGetters('checkout',{
      basket: 'BASKET'
    }),
  },
  methods: {
    ...mapActions('events',{
      openRegistration: ActionTypes.openRegistration,
    }),
    ...mapActions('auth',{
      isAuthorized: 'IS_AUTHORIZED',
    }),
    ...mapActions('checkout', {
      setBasket: 'SET_BASKET'
    }),
    async submit() {
      this.submitted = true
      const validInput = this.validateInput()
      if (validInput) {
        this.loaderShown = true
        this.$emit('submit')
        await this.openRegistration({eventId: this.eventId, email: this.email, instagram: this.instagram})
        this.$emit('confirm')
      }
    },
    validateInput() {
      if (this.submitted) {
        this.emailColor = this.validateEmail(this.email)? '' : 'danger'
        this.instagramColor = this.instagram ? '' : 'danger'
        this.buttonColor = (this.email && this.instagram)  ? 'medium' : 'danger'
        return (this.validateEmail(this.email) && this.instagram)
      }
    },
    purchase(item) {
      const index = this.basket.findIndex(el => el.itemId == item.hashId);
      if (index == -1) {
        this.basket.push({itemId: item.hashId, price: item.price})
        this.setBasket(this.basket)
        this.presentToastBasket()
      } else {
        this.basket.splice(index, 1)
        this.setBasket(this.basket)
      }
    },
    async presentToastBasket() {
      var basketToast = await toastController.create({
        header: 'Přidáno do košíku ✅',
        message: 'Klikněte pro zobrazení objednávky.',
        buttons: [
          {
            text: 'Košík',
            role: 'submit',
            icon: cartOutline,
            handler: () => {
              this.isAuthorized ? this.$router.push({path: this.$t('toolbar.path_checkout')}) : this.$router.push({path: this.$t('toolbar.path_signup')})
            }
          }
        ],
      });

      
      basketToast.present();
      setInterval(() => {
        basketToast.dismiss();
      }, 2000);
    },
  },
  
});
