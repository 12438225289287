import { state } from '@/modules/events/business/state'

export type RootState = ReturnType<typeof state>

export enum GettersTypes {
  events = 'EVENTS',
}

export const getters = {
  [GettersTypes.events]: (data) => data?.events,
}
