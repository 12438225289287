
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { QrcodeStream } from 'vue3-qrcode-reader'
import ScanResponse from '../components/ScanResponseComponent.vue'
import { ScanStatuses } from '../../enums'

export default defineComponent({
name: 'ScannerPage',
  components: {
    IonContent,
    IonPage,
    QrcodeStream,
    ScanResponse
  },
  data() {
    return {
      scanned: false,
      ScanStatuses
    }
  },
  computed: {
    ...mapGetters('internal',{
      scanData: GettersTypes.scanData,
    }),
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED',
    }),
  },
  methods: {
    ...mapActions('internal',{
      scan: ActionTypes.scan,
    }),
    onDecode(decodedString) {
      this.scanned = false
      this.scan({ticket_token: decodedString, access_token: this.auth.token})
    },
    hideDetails() {
      this.scanned = false
    }
  },
  watch: {
    scanData() {
      this.scanned = true
    }
  }
});
