import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-777d8bfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sign-in-header" }
const _hoisted_2 = { class: "confirm-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_subtitle = _resolveComponent("ion-subtitle")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel,
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.closeOutline,
                      color: "danger",
                      size: "large"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, {
              innerHTML: _ctx.$t('auth.forgotten_password.modal_title')
            }, null, 8, ["innerHTML"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_title, {
            innerHTML: _ctx.$t('auth.forgotten_password.title')
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_ion_subtitle, {
            innerHTML: _ctx.$t('auth.forgotten_password.subtitle')
          }, null, 8, ["innerHTML"]),
          _createElementVNode("form", null, [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, {
                  position: "floating",
                  color: this.emailDanger,
                  innerHTML: _ctx.$t('auth.forgotten_password.email')
                }, null, 8, ["color", "innerHTML"]),
                _createVNode(_component_ion_input, {
                  value: this.prefilledEmail,
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateInput())),
                  type: "email"
                }, null, 8, ["value", "modelValue"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (this.submit())),
                color: this.buttonDanger,
                innerHTML: _ctx.$t('auth.forgotten_password.button_send')
              }, null, 8, ["color", "innerHTML"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}