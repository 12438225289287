export enum GettersTypes {
  isAuthorized = 'IS_AUTHORIZED',
  auth = 'AUTH',
  accountInfo = 'ACCOUNT_INFO',
  invalidCredentials = 'INVALID_CREDENTIALS',
  userExists = 'USER_EXISTS',
  prefillData = 'PREFILL_DATA'
}

export const getters = {
  [GettersTypes.isAuthorized]: (data) => data?.isAuthorized,
  [GettersTypes.auth]: (data) => data?.auth,
  [GettersTypes.accountInfo]: (data) => data?.accountInfo,
  [GettersTypes.invalidCredentials]: (data) => data?.invalidCredentials,
  [GettersTypes.userExists]: (data) => data?.userExists,
  [GettersTypes.prefillData]: (data) => data?.prefillData,
}
