import { MutationTree } from 'vuex'
import { State, state } from './state'
import { ScanData, Registrations, Payments, Users, CheckoutPages } from '../types'
import { ScanStatuses } from '../enums'


export enum MutationTypes {
    search = 'SEARCH',
    table = 'TABLE',
    scanData = 'SCAN_DATA',
    scanStatus = 'SCAN_STATUS',
    loading = 'LOADING',
    registrations = 'REGISTRATIONS',
    payments = 'PAYMENTS',
    users = "USERS",
    checkoutPages = "CHECKOUT_PAGES",
    editToggle = 'EDIT_TOGGLE'
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
    [MutationTypes.search](state: State, value: string) {
        state.search = value;
    },
    [MutationTypes.table](state: State, table: string) {
        state.table = table;
    },
    [MutationTypes.scanData](state: State, scanData: ScanData) {
        state.scanData = scanData;
    },
    [MutationTypes.scanStatus](state: State, scanStatus: ScanStatuses) {
        state.scanStatus.status = scanStatus;
    },
    [MutationTypes.loading](state: State, loading: boolean) {
        state.loading = loading;
    },
    [MutationTypes.registrations](state: State, registrations: Registrations) {
        state.registrations = registrations;
    },
    [MutationTypes.payments](state: State, payments: Payments) {
        state.payments = payments;
    },
    [MutationTypes.users](state: State, users: Users) {
        state.users = users;
    },
    [MutationTypes.checkoutPages](state: State, checkoutPages: CheckoutPages) {
        state.checkoutPages = checkoutPages;
    },
    [MutationTypes.editToggle](state: State, edit: boolean) {
        state.editToggle = edit;
    },
}
