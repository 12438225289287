
import { IonIcon, IonList, IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import { TypeColor, TypeIcon } from '../enums';
import { headset, book, pencil } from 'ionicons/icons';
import { BlogTypes } from '@/modules/blog/enums'

export default defineComponent({
  name: 'IconBadge',
  props: {
    edit: {
      type: String
    },
    preset: {
      type: String,
      default() {
        return 'podcast'
      }
    }
  },
  data() {
    return {
      value: this.preset,
      TypeColor,
      TypeIcon,
      BlogTypes
    }
  },
  components: {
    IonIcon,
    IonList, 
    IonItem, 
    IonSelect, 
    IonSelectOption,
  },
  methods: { 
    getIcon(item) {
      let icons = {
        headset: headset, 
        book: book, 
        pencil: pencil
      };
      if (item in icons) return icons[item];
    }
  },
  setup() {
    return {
      headset,
      book,
      pencil
    }
  },
  watch: {
    value() {
      this.$emit('value', this.value)
    }
  }
});
