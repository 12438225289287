
import { IonCard } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { GettersTypes } from '../../business'

export default defineComponent({
name: 'ScanResponse',
  components: {
    IonCard
  },
  computed: {
    ...mapGetters('internal',{
      scanData: GettersTypes.scanData,
      scanStatus: GettersTypes.scanStatus,
    }),
  },
});
