
  import {  IonFab,
            IonFabList,
            IonFabButton,
            IonIcon } from '@ionic/vue';
  import { menuOutline, chevronBack, musicalNotesOutline, ticketOutline, personCircleOutline, wineOutline, logInOutline, logOutOutline, accessibilityOutline, searchOutline, personOutline, personAddOutline, scanOutline, documentTextOutline, homeOutline, bookOutline, bulbOutline } from 'ionicons/icons';
  import { defineComponent } from 'vue';
  import { mapGetters, mapActions } from 'vuex'
  import { Tables } from '@/modules/internal/enums'
  import InternalNavigation from './InternalNavigationComponent.vue'
  import { Roles } from '@/modules/auth/enums'
  
  export default defineComponent({
    name: 'FabMenu',
    components: {
      IonFab,
      IonFabList,
      IonFabButton,
      IonIcon,
      InternalNavigation
    },
    props: {
      isAuthorized: {
        type: Boolean
      },
      forceClose: {
        type: Boolean
      }
    },
    data() {
      return {
        isMenuOpen: false,
        activeMenuColor: 'dark',
        search: "",
        backAllowed: false,
        Tables
      }
    },
    setup() {
      return {
        menuOutline,
        chevronBack,
        musicalNotesOutline,
        ticketOutline,
        personCircleOutline,
        wineOutline,
        logInOutline, 
        logOutOutline,
        accessibilityOutline,
        searchOutline,
        personOutline,
        personAddOutline,
        scanOutline,
        documentTextOutline,
        homeOutline,
        bookOutline,
        bulbOutline
      }
    },
    computed: {
      ...mapGetters({
        isAuthorized: 'IS_AUTHORIZED',
        auth: 'AUTH'
      }),
      ...mapGetters('internal',{
        table: 'TABLE'
      }),
    },
    methods: {
      ...mapActions({
        signOut: 'SIGN_OUT'
      }),
      ...mapActions('internal',{
        setSearch: 'SEARCH',
        selectTable: 'SELECT_TABLE',
      }),
      click() {
        this.isMenuOpen = !this.isMenuOpen
        this.$emit('fabClick')
      },
      back() {
        var currentRoute = this.$router.currentRoute.value.path
        if (this.$router.currentRoute.value.path?.split('/')[1] == 'lekce') {
          this.$router.push({path: '/lekce'})
        } else if (this.$router.currentRoute.value.path?.split('/')[1] == 'kosik') {
          this.$router.push({path: '/kurzy'})
        } else {
          this.$router.go(-1)
        }
        this.isMenuOpen = false
      },
      isCurrentPath(path) {
        var currentRoute = this.$router.currentRoute.value.path
        currentRoute = currentRoute.slice(-1) == '/' ? currentRoute.slice(0,-1) : currentRoute
        return path == currentRoute || path.includes(currentRoute)
      },
      isInternalRole() {
        return (this.auth.role == Roles.ADMIN || this.auth.role == Roles.INTERNAL)
      },
      openWix() {
        window.open('https://taktrochen.cz', '_blank')
      }
    },
    watch: {
      forceClose() {
        if (this.forceClose == true) {
          this.isMenuOpen = false
        }
      },
      '$route' (to, from) {
        this.backAllowed = this.$route.meta.back
      }
    }
  });
  