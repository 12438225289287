import { MutationTree } from 'vuex'
import { State, state } from './state'
import { Checkout, Contact, Token } from '../types'

export enum MutationTypes {
  checkout = 'CHECKOUT',
  basket = 'BASKET',
  setBasket = 'SET_BASKET',
  incrementBasket = 'INCREMENT_BASKET',
  clearBasket = 'CLEAR_BASKET',
  loaderShown = 'LOADER_SHOWN',
  setContact = 'SET_CONTACT',
  upadteTokens = 'UPDATE_TOKENS',
  transaction = 'TRANSACTION'
  
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  [MutationTypes.checkout](state: State, checkout: Checkout) {
    state.checkout = checkout;
  },
  [MutationTypes.setBasket](state: State, basket: [{ itemId, price }]) {
    state.basket = basket
    let sum = 0
    basket.forEach(function(item) {
      sum += item.price;
    })
    state.totalPrice = sum
  },
  [MutationTypes.incrementBasket](state: State, { itemId, price }) {
    const index = state.basket.findIndex(item => item.itemId == itemId);
    if (index == -1) {
      state.basket.push({itemId: itemId, price: price})
    
      let sum = 0
      state.basket?.forEach(function(item) {
        sum += item.price;
      })
      state.totalPrice = sum
    }
  },
  [MutationTypes.clearBasket](state: State) {
    state.basket = [{itemId: '', price: 0}]
  },
  [MutationTypes.loaderShown](state: State, shown: boolean) {
    state.loaderShown = shown;
  },
  [MutationTypes.setContact](state: State, contact: Contact) {
    state.contact.email = contact.email;
    state.contact.instagram = contact.instagram;
  },
  [MutationTypes.upadteTokens](state: State, tokens: [Token]) {
    state.tokens = tokens;
  },
  [MutationTypes.transaction](state: State, transaction: string) {
    state.transaction = transaction;
  },
}
