import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-559dcd46"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "new"
}
const _hoisted_2 = { class: "customize-header" }
const _hoisted_3 = {
  key: 1,
  class: "dot-carousel"
}
const _hoisted_4 = {
  key: 1,
  class: "data"
}
const _hoisted_5 = {
  key: 1,
  class: "data"
}
const _hoisted_6 = {
  key: 1,
  class: "data"
}
const _hoisted_7 = {
  key: 1,
  class: "data"
}
const _hoisted_8 = {
  key: 1,
  class: "data"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "dot-carousel"
}
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_tool_button = _resolveComponent("tool-button")!
  const _component_vue3_easy_data_table = _resolveComponent("vue3-easy-data-table")!

  return (_openBlock(), _createBlock(_component_vue3_easy_data_table, {
    "buttons-pagination": "",
    alternating: "",
    headers: _ctx.headers,
    items: this.data,
    loading: this.data.length ? false : this.loading,
    "search-value": this.search,
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeTools()))
  }, {
    [`item-new`]: _withCtx((item) => [
      (item.new)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    [`header-tools`]: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (this.data.length ? !this.loading : true)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              icon: _ctx.refresh,
              class: "refresh",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (this.refreshTable()))
            }, null, 8, ["icon"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3))
      ])
    ]),
    [`item-creation_time`]: _withCtx(({ creation_time }) => [
      _createTextVNode(_toDisplayString(this.formattedTime(creation_time)), 1)
    ]),
    [`item-name`]: _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.name,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.name) = $event),
            value: item.name
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.name), 1))
    ]),
    [`item-surname`]: _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.surname,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.surname) = $event),
            value: item.surname
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.surname), 1))
    ]),
    [`item-street`]: _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.street,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.street) = $event),
            value: item.street
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.street), 1))
    ]),
    [`item-city`]: _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.city,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.city) = $event),
            value: item.city
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(item.city), 1))
    ]),
    [`item-postalCode`]: _withCtx((item) => [
      (item.edit)
        ? (_openBlock(), _createBlock(_component_ion_input, {
            key: 0,
            modelValue: item.editDetails.postalCode,
            "onUpdate:modelValue": ($event: any) => ((item.editDetails.postalCode) = $event),
            value: item.postalCode
          }, null, 8, ["modelValue", "onUpdate:modelValue", "value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.postalCode), 1))
    ]),
    [`item-tools`]: _withCtx((item) => [
      (!item.edit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_ion_icon, {
              icon: _ctx.pencil,
              class: "pencil",
              onClick: ($event: any) => (_ctx.editDetails(item))
            }, null, 8, ["icon", "onClick"]),
            _createVNode(_component_tool_button, {
              item: item,
              forceClose: this.forceCloseTools
            }, null, 8, ["item", "forceClose"])
          ]))
        : (item.editLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_ion_icon, {
                icon: _ctx.checkmark,
                color: "success",
                onClick: ($event: any) => (_ctx.updateDetails(item))
              }, null, 8, ["icon", "onClick"]),
              _createVNode(_component_ion_icon, {
                icon: _ctx.close,
                color: "danger",
                onClick: ($event: any) => (_ctx.editDetails(item))
              }, null, 8, ["icon", "onClick"])
            ]))
    ]),
    _: 2
  }, 1032, ["headers", "items", "loading", "search-value"]))
}