
import { IonItem, IonLabel, IonInput, IonLoading, modalController, IonIcon } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import ForgottenPasswordModal from './modal/ForgottenPasswordModal.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

import { addOutline } from 'ionicons/icons';

export default defineComponent({
name: 'SignIn',
  components: {
    IonItem,
    IonLabel,
    IonInput,
    // IonLoading,
    ButtonLoading,
    IonIcon
  },
  props: {
    prefilledEmail: {
      type: String
    },
    unAuthorized: {
      type: Boolean
    },
    createAccount: {
      type: Boolean,
      default() {
        return true
      }
    },
    styling: {
      type: Object,
      default() {
        return { 
          buttonText: 'Přihlašte se',
          buttonColor: 'dark'
         }
      }
    },
    requestDeleteAccount: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      loaderShown: false,
      submitted: false,
      emailDanger: "",
      passwordDanger: "",
      buttonDanger: "dark",
      email: this.prefilledEmail ? this.prefilledEmail : this.$route.query.email,
      password: "",
    }
  },
  setup() {
      return {
        addOutline,
      }
    },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized,
      invalidCredentials: GettersTypes.invalidCredentials
    }),
  },
  methods: {
    ...mapActions({
      signIn: ActionTypes.signIn,
      deleteAccount: ActionTypes.deleteAccount
    }),
    async submit() {
      this.submitted = true
      const validInput = this.validateInput()
      if (validInput) {
        this.loaderShown = true
        await this.signIn({email: this.email, password: this.password})
        this.loaderShown = false
        if (!this.invalidCredentials) {
          this.$emit('confirm')
          if (this.$route.query.redirect && this.$route.query.share) {
            this.$router.push({path: this.$route.query.redirect, query: {share: this.$route.query.share}})
          } else if (this.$route.query.redirect ) {
            this.$router.push(this.$route.query.redirect)
          } else if (this.requestDeleteAccount) {
            await this.deleteAccount({email: this.email, password: this.password})
            this.$router.push({path: this.$t('toolbar.path_signout')})
          } else {
            this.$router.push({path: this.$t('toolbar.path_events')})
          }
          
        } else {
          this.invalidCredentials = true
          this.buttonDanger = 'danger'
        }
        
      }
    },
    validateInput() {
      if (this.submitted) {
        this.passwordDanger = this.password.length >= 8 ? '' : 'danger'
        this.buttonDanger = (this.password && this.email)  ? this.styling.buttonColor : 'danger'
        this.emailDanger = this.validateEmail(this.email) ? '' : 'danger'
        this.invalidCredentials = false
        return (this.password.length >= 8 && this.validateEmail(this.email))
      } else {
        return true
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async openForgottenPasswordModal() {
      const modal = await modalController.create({
        component: ForgottenPasswordModal,
        componentProps: {prefilledEmail: this.email}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      // if (role === 'confirm') {
      //   this.$emit('showLoader')
      // }
    },
  },
  mounted() {
    if (this.isAuthorized && this.$route.query.redirect) {
      if (this.$route.query.share) {
        this.$router.push({path: this.$route.query.redirect, query: {share: this.$route.query.share}})
      } else {
        this.$router.push({path: this.$route.query.redirect})
      }
    }
  },
  watch: {
    isAuthorized() {
      if (this.isAuthorized && this.$route.query.redirect) {
        if (this.$route.query.share) {
          this.$router.push({path: this.$route.query.redirect, query: {share: this.$route.query.share}})
        } else {
          this.$router.push({path: this.$route.query.redirect})
        }
      }
    }
  }
});
