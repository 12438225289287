import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86a8078c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    color: this.scanStatus == 'SUCCESS' ? 'success' : (this.scanStatus == 'SCANNED' ? 'warning' : (this.scanStatus == 'INVALID' ? 'danger' : 'light'))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(this.scanStatus) + " " + _toDisplayString(this.scanStatus == 'SCANNED' ? this.scanData.scanned + 'x' : ''), 1),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(this.scanData.item), 1),
        _createElementVNode("p", null, _toDisplayString(this.scanData.title), 1),
        _createElementVNode("p", null, "Price: " + _toDisplayString(this.scanData.price) + "€", 1),
        _createElementVNode("h3", null, _toDisplayString(this.scanData.name) + " " + _toDisplayString(this.scanData.surname), 1),
        _createElementVNode("p", null, _toDisplayString(this.scanData.creation_time), 1)
      ])
    ]),
    _: 1
  }, 8, ["color"]))
}