
import { IonBadge } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../../business'
import { refresh } from "ionicons/icons";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default defineComponent({
name: 'PaymentsTable',
  components: {
    IonBadge,
    // IonCheckbox,
    ButtonLoading,
    Vue3EasyDataTable,
    // ProgressBar
  },
   setup() {
    return {
      refresh
    }
  },
  computed: {
    ...mapGetters('internal',{
      search: GettersTypes.search,
      payments: GettersTypes.payments,
      loading: GettersTypes.loading
    }),
  },
  methods: {
    ...mapActions('internal',{
      getPayments: ActionTypes.getPayments,
      scan: ActionTypes.scan
    }),
    formattedTime(time) {
      var date = new Date(time)
      var formattedTime = date.toLocaleTimeString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) 
      return formattedTime
    },
    scanTicket(item) {
      this.scan({ticket_token: item.ticket_token, access_token: this.$cookies.get('access_token')})
      var index = this.payments.findIndex(function(e) {return e.id == parseInt(item.id)})
      this.payments[index].scanned = true
    },
    async refreshTable() {
      this.refreshLoading = true
      await this.getPayments(this.$cookies.get('access_token'))
      var ids = this.data.map(a => a.id)
      var newData = this.payments.filter(e => !ids.includes(e.id))
      if (newData.length) {
        this.data = newData.concat(this.data)
      }
      this.refreshLoading = false
    }
  },
  data() {
    return {
      headers: [
        { text: "CREATION TIME", value: "creation_time", sortable: true},
        { text: "EVENT", value: "event", sortable: true},
        { text: "EMAIL", value: "email", sortable: true},
        { text: "TITLE", value: "title", sortable: true},
        { text: "ITEM", value: "item", sortable: true},
        { text: "QUANTITY", value: "quantity", sortable: true},
        { text: "PRICE", value: "price", sortable: true},
        { text: "SCANNED", value: "scanned", sortable: true},
        { text: "", value: "tools"},
        
      ],
      data: []
    }
  },
  async mounted() {
    await this.getPayments(this.$cookies.get('access_token'))
    this.data = this.payments
  }
})
