
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import RegistrationsTable from '../components/tables/RegistrationsTableComponent.vue'
import PaymentsTable from '../components/tables/PaymentsTableComponent.vue'
import UsersTable from '../components/tables/UsersTableComponent.vue'
import { Tables } from '../../enums'

export default defineComponent({
name: 'TablePage',
  components: {
    IonContent,
    IonPage,
    PaymentsTable,
    UsersTable
  },
  data() {
    return {
      Tables
    }
  },
  computed: {
    ...mapGetters('internal',{
      scanData: GettersTypes.scanData,
      table: GettersTypes.table
    }),
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED',
    })
  },
  methods: {
    ...mapActions('internal',{
      scan: ActionTypes.scan,
    }),
  },
});
