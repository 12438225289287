
import { IonContent, IonPage, IonList, IonItem, IonLabel, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '@/modules/events/business'
import EventCard from '../components/EventCardComponent.vue'
import EditEvent from '../components/EditEventComponent.vue'
import { addCircleOutline } from "ionicons/icons";

import axios from 'axios'
   
const __default__ = defineComponent({
name: 'TicketingPage',
  components: {
    IonContent,
    IonPage,
    IonList,
    
    EventCard,
    EditEvent,
    // IonLoading,
    
    // IonInfiniteScroll,
    // IonInfiniteScrollContent
  },
  data() {
    return {
      loaderShown: true,
      pastEvents: Object,
      showSkeleton: true,
      addNewEvent: false
    }
  },
  computed: {
    ...mapGetters('events',{
      events: GettersTypes.events
    }),
    ...mapGetters({
      isAuthorized: 'IS_AUTHORIZED',
      auth: 'AUTH'
    }),
    backgroundImage() {
      return 'url(' + process.env.VUE_APP_ASSETS_URL + '/icon/red-pattern.png)'
    },
  },
  methods: {
    ...mapActions('events',{
      getEvents: ActionTypes.getEvents,
    }),
    hideLoader() {
      this.loaderShown = false
    },
    getUpcomingEvents() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() - 1)
      return this.events.filter(e => new Date(e.startDate) >= tomorrow)
    },
    getPastEvents() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() - 1)
      return this.events.filter(e => new Date(e.startDate) < tomorrow).slice().reverse()
    },
    isInternalRole() {
      var token = this.auth.token
      if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload).role != 'user';
      }
      return false
    },
    getColumnsEvents(column) {
      if (column == 'first') {
        var firstEvents = structuredClone(this.events)
        firstEvents = firstEvents.filter(function(e, i) {return (i+1) % 2 != 0})
        if (this.events.length % 2 != 0) {
          firstEvents.splice(-1)
        }
        return firstEvents
      } else {
        var secondEvents = structuredClone(this.events)
        secondEvents = secondEvents.filter(function(e, i) {return (i+1) % 2 == 0})
        return secondEvents
      }
    },
    getLastOddEvent() {
      if (this.events.length % 2 != 0) {
        var lastEvent = structuredClone(this.events)
        return lastEvent.slice(-1).pop()
      } else {
        return undefined
      }
    }
  },
  setup() {
    const isDisabled = ref(false);
    const toggleInfiniteScroll = () => {
      isDisabled.value = !isDisabled.value;
    }
    const items = ref<number[]>([]);
    const pushData = () => {
      const max = items.value.length + 2;
      const min = max - 2;
      for (let i = min; i < max; i++) {
        items.value.push(15-i); // TODO HARDCODED LIST
      }
    }
    
    const loadData = (ev: any) => {
      setTimeout(() => {
        pushData();
        ev.target.complete();
  
        if (items.value.length === 2) { // TODO HARDCODED LIST
          ev.target.disabled = true;
        }
      }, 1000);
    }
    
    pushData();
    
    return {
      isDisabled,
      toggleInfiniteScroll,
      loadData,
      items,
      addCircleOutline
    }
    
  },
  async mounted() {
    this.events = []
    this.getEvents(this.$cookies.get('access_token'))
      
//     var path = "https://payments.comgate.cz/v1.0/create"
//     var options = {headers: {'content-type': 'application/x-www-form-urlencoded'}}
//     var data = {
//     "merchant": "470418",
//     "price": "10000",
//     "curr": "CZK",
//     "label": "Beatles -20Help",
//     "refId": "123456789",
//     "method": "ALL",
//     "prepareOnly": true,
//     "secret": "IpkHgZerFdqX3ZSGdINJlfthy1DcViOp"
// }
//     await axios.post(path, data, options).then((response) => {
//       console.log(response)
//     }).catch((error) => {
//       console.log(error)
//     })

  },
  watch: {
    events() {
      this.hideLoader()
      this.loaderShown = false
    },
    isAuthorized() {
      this.getEvents(this.auth.token)
    }
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "17126fac": (_ctx.backgroundImage)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__