import { MutationTree } from 'vuex'
import { State, state } from '@/modules/events/business/state'
import { State as StatePortfolio } from '@/modules/portfolio/business/state'
import { UserEventState } from '../enums'
import { Event } from '../types'

export enum MutationTypes {
  openRegistration = 'OPEN_REGISTRATION',
  preApprovedToEvent = 'PRE_APPROVED_TO_EVENT',
  getEvents = 'GET_EVENTS',
  getNextEvent = 'GET_NEXT_EVENTS',
  cancelRegistration = 'CANCEL_REGISTRATION',
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  [MutationTypes.getEvents](state: State, events: [Event]) {
    state.events = events;
  },
  [MutationTypes.getNextEvent](state: State, event: Event) {
    state.events = [event];
  },
}
