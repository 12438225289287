
import { IonIcon, IonList, IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { Level, LevelColor } from '../enums';

export default defineComponent({
  name: 'LevelBadge',
  props: {
    edit: {
      type: String
    },
    preset: {
      type: String,
      default() {
        return 'A1'
      }
    }
  },
  components: {
    IonList, 
    IonItem, 
    IonSelect, 
    IonSelectOption
  },
  data() {
    return {
      value: this.preset,
      Level,
      LevelColor,
    }
  },
  watch: {
    value() {
      this.$emit('value', this.value)
    }
  }
});
