import { Checkout, Basket, Contact, Token } from '../types'

export type State = {
  checkout: Checkout,
  basket: [Basket],
  totalPrice: number,
  loaderShown: boolean,
  contact: Contact,
  tokens: [Token],
  transaction?: string
};

export const state = (): State => ({
  checkout: {
    pageId: 0,
    eventId: 0,
    stripePk: "pk_test_51JTCBWBqGzfhNObJbqzg1aCdEuxRdX4hbalmpQgtLtaJJr1mRnh6VCysxgvHMyk0xTo3n3poorN0meXXfLjudOvx005SDX7F3A",
    title:"",
    subtitle:"",
    items:[{itemId: 1, name: "", price: 2, priceId:"price_1KKmVEBqGzfhNObJ1bWi88Vv"}],
    serviceFee:{itemId: 1, price: 2, name: "", priceId:"price_1KKmVEBqGzfhNObJ1bWi88Vv"},
    disabled: true
  },
  basket: [{itemId: '', price: 0}],
  totalPrice: 0,
  loaderShown: true,
  contact: {email: "", instagram:""},
  tokens: [{
    email:"",
    creationTime:"",
    price:0,
    ticketToken:"",
    quantity:0,
    scanned: false}]
})
