
import { mapActions } from 'vuex'
import { ActionTypes } from '../../business'
import { IonContent, IonTitle, IonInput, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default defineComponent({
    name: 'ForgottenPasswordPage',
    props: {
        prefilledEmail: {
            type: String
        }
    },
    data() {
      return {
        loaderShown: false,
        submitted: false,
        passwordDanger: "",
        password: "",
        buttonDanger: "primary"
      }
    },
    components: { 
      IonContent, 
      IonTitle,
      IonInput,
      IonLabel,
      ButtonLoading
    },
    setup() {
      return {
        closeOutline
      }
    },
    methods: {
    ...mapActions({
      resetPassword: ActionTypes.resetPassword,
    }),
    async submit() {
      this.submitted = true
      if (this.validateInput()) {
        this.loaderShown = true
        await this.resetPassword({token: this.$route.query.token, password: this.password})
        this.$router.push({path: this.$t('toolbar.path_signin')})
      } else {
        this.loaderShown = false
      }
    },
    validateInput() {
      if (this.submitted) {
        
        this.passwordDanger = this.password.length >= 8 ? '' : 'danger'
        this.buttonDanger = this.password.length >= 8  ? 'primary' : 'danger'
        return this.password.length >= 8
      }
    }
  },
});
