import { MutationTypes } from './mutations'
import axios from 'axios'
import { useToast } from "vue-toastification";

export enum ActionTypes {
  signIn = 'SIGN_IN',
  signUp = 'SIGN_UP',
  signOut = 'SIGN_OUT',
  createAccount = "CREATE_ACCOUNT",
  userDetails = 'USER_DETAILS',
  verifyToken = 'VERIFY_TOKEN',
  checkEmail = 'CHECK_EMAIL',
  setToken = 'SET_TOKEN',
  getAccountInfo = 'GET_ACCOUNT_INFO',
  confirmPayment = 'CONFIRM_PAYMENT',
  sendRenewalEmail = 'SEND_RENEWAL_EMAIL',
  resetPassword = 'RESET_PASSWORD',
  updateAccountInfo = 'UPDATE_ACCOUNT_INFO',
  getPrefillData = 'GET_PREFILL_DATA',
  deleteAccount = 'DELETE_ACCOUNT'
}


export const actions = {
  async [ActionTypes.signIn]({ dispatch, commit }, { email, password }) {
    const path = process.env.VUE_APP_BASE_URL+'/get_token/'
    const data = {username: email, password: password}
    await axios.post(path, data).then((response) => {
      commit(MutationTypes.invalidCredentials, false)
      dispatch(ActionTypes.setToken, {token: response.data.access_token, role: response.data.role, email: response.data.email})
    }).catch((error) => {
      if (error.response.status == 401) {
        commit(MutationTypes.invalidCredentials, true)
      } else {
        useToast().error("Something went wrong!\nRefresh the window and try again.")
      }
    })
  },
  async [ActionTypes.verifyToken]({ dispatch, commit }, token) {
    if (token) {
      const path = process.env.VUE_APP_BASE_URL+'/verify_token/' + (token ? token : null)
      await axios.get(path).then((response) => {
        dispatch(ActionTypes.setToken, {token: response.data.access_token, role: response.data.role, email: response.data.email})
      }).catch((error) => {
        if (error.response.status == 401) {
          dispatch(ActionTypes.setToken, undefined)
          commit(MutationTypes.isAuthorized, false)
        } else {
          useToast().error("Something went wrong!\nRefresh the window and try again.")
        }
      })
    } else {
      commit(MutationTypes.isAuthorized, false)
    }
  },
  async [ActionTypes.checkEmail]({ commit }, email) {
    const path = process.env.VUE_APP_BASE_URL+'/check_email/' + email
    await axios.get(path).then((response) => {
      if (response.status == 201) {
        commit(MutationTypes.userExists, response.data.site)
      } else if (response.status == 202) {
        commit(MutationTypes.userExists, 'prefill')
      } else {
        commit(MutationTypes.userExists, "")
      }
    }).catch((error) => {
      console.log("Email check failed!")
    })
  },
  async [ActionTypes.createAccount]({ dispatch, commit }, data) {
    const path = process.env.VUE_APP_BASE_URL+'/create-account/complete/'
    await axios.post(path, data).then((response) => {
      dispatch(ActionTypes.setToken, {token: response.data.access_token, role: response.data.role, email: response.data.email})
      return response.data.access_token
    }).catch((error) => {
      if (error.response.status == 409) {
        commit(MutationTypes.userExists, "true")
      } else {
        useToast().error("Something went wrong!\nRefresh the window and try again.")
      }
    })
  },
  async [ActionTypes.signUp]({ dispatch, commit }, { email, instagram, password, hashId=undefined }) {
    const path = process.env.VUE_APP_BASE_URL+'/create-account/fast/'
    const data = {email: email, instagram: instagram, password: password, hashId: hashId}
    await axios.post(path, data).then((response) => {
      dispatch(ActionTypes.setToken, {token: response.data.access_token, role: response.data.role, email: response.data.email})
      return response.data.access_token
    }).catch((error) => {
      if (error.response.status == 409) {
        commit(MutationTypes.userExists, "true")
      } else {
        useToast().error("Something went wrong!\nRefresh the window and try again.")
      }
    })
  },
  async [ActionTypes.userDetails]({ dispatch, commit }, { token, name, surname, document, phone=undefined }) {
    const path = process.env.VUE_APP_BASE_URL+'/complete-account/finish/'+token
    const data = {name: name, surname: surname, document: document, phone: phone}
    await axios.post(path, data).then((response) => {
      return "OK"
    }).catch((error) => {
      useToast().error("Something went wrong!\nRefresh the window and try again.")
    })
  },
  async [ActionTypes.signOut]({ dispatch, commit}) {
      dispatch(ActionTypes.setToken, {token: undefined, role: undefined, email: undefined})
      commit(MutationTypes.isAuthorized, false)
  },
  async [ActionTypes.setToken]({ commit }, data) {
    if (data?.token) {
      commit(MutationTypes.isAuthorized, true)
      commit(MutationTypes.setToken, data)
    } else {
      commit(MutationTypes.isAuthorized, false)
      commit(MutationTypes.setToken, "")
    }
  },
  async [ActionTypes.getAccountInfo]({ commit }, token) {
    const path = process.env.VUE_APP_BASE_URL+'/account/' + token
    await axios.get(path).then((response) => {
      if (response.status == 200) {
        commit(MutationTypes.isAuthorized, true)
        commit(MutationTypes.getAccountInfo, response.data)
      } else {
        commit(MutationTypes.isAuthorized, false)
        commit(MutationTypes.setToken, undefined)
      }
    })
  },
  async [ActionTypes.confirmPayment]({ commit }, paymentId) {
    const path = process.env.VUE_APP_BASE_URL+'/confirm_payment/' + paymentId
    axios.get(path).then((response) => {
      useToast().success('Payment confirmed!\nYou\'ll find you entrance here.')
    }).catch(function (error) {
      useToast().error('Payment confirmation failed! No worries, contact us and we\'ll tackle it!')
    });
  },
  async [ActionTypes.sendRenewalEmail]({ commit }, email ) {
    const path = process.env.VUE_APP_BASE_URL+'/send-renewal-email/' + email
    axios.get(path).then((response) => {
      useToast().success('Obnovovací email odeslán, prosím zkontrolujte svou příchozí poštu i spam.')
    })
  },
  async [ActionTypes.resetPassword]({ commit }, {token, password} ) {
    const path = process.env.VUE_APP_BASE_URL+'/reset-password/'
    const data = {token: token, password: password}
    await axios.post(path, data).then((response) => {
      useToast().success('Heslo zmeněno!')
    })
  },
  async [ActionTypes.updateAccountInfo]({ commit }, data ) {
    const path = process.env.VUE_APP_BASE_URL+'/update-account/'
    await axios.post(path,data).then((response) => {
      useToast().success('Vaše údaje aktualizovány!')
    })
  },
  async [ActionTypes.getPrefillData]({ commit }, hashId) {
    const path = process.env.VUE_APP_BASE_URL+'/prefill_data/' + hashId
    await axios.get(path).then((response) => {
      commit(MutationTypes.prefillData, response.data)
    })
  },
  async [ActionTypes.deleteAccount]({ dispatch }, { email, password }) {
    const path = process.env.VUE_APP_BASE_URL+'/delete-account/'
    const data = {username: email, password: password}
    await axios.post(path, data).then((response) => {
      dispatch(ActionTypes.signOut)
    })
  },


}

