
<script>
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default {
    name: 'ExpandableText',
    components: {
        ButtonLoading
    },
    props: {
        post: {
            type: Object,
        required: true
        }
    },
    data() {
        return {
            expanded: false,
            showButton: false
        };
    },
    methods: {
        toggleExpand() {
            this.expanded = !this.expanded;
        }
    },
};
</script>

<template>
    <div ref="container" class="expandable-container" :style="{ maxHeight: expanded ? 'none' : '100px' }">
        <ion-text v-if="post" v-html="post.content"></ion-text>    
    </div>
    <ButtonLoading 
        v-if="post.content.length > 600"
        @click="toggleExpand" 
        :text="expanded ? 'Číst méně' : 'Číst více'"  
        size="small" 
        style="margin-top: 10px;"
        color="light"
    />
        
    
</template>

<style scoped>
.expandable-container {
  overflow-y: hidden;
  transition: max-height 0.2s ease-in-out;
}


</style>
