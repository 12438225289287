
import { IonContent, IonPage, IonTitle, IonCard, IonLoading, IonInput, IonLabel, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { createOutline } from 'ionicons/icons';
import DeleteAccountModal from '../components/modal/DeleteAccountModal.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

const __default__ = defineComponent({
name: 'AccountInfoPage',
  components: {
    IonContent,
    IonPage,
    IonTitle,
    IonCard,
    IonLoading,
    IonInput,
    IonLabel,
    ButtonLoading
  },
  setup() {
    return {
      createOutline
    }
  },
  data() {
    return {
      loaderShown: true,
      buttonLoading: false,
      user: {
        name: "",
        surname: "",
        email: "",
        street: "",
        city: "",
        postalCode: "",
      },
      editActive: false
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: GettersTypes.accountInfo,
    }),
    backgroundImage() {
      return 'url(' + process.env.VUE_APP_ASSETS_URL + '/icon/red-pattern.png)'
    },
  },
  methods: {
    ...mapActions({
      getAccountInfo: ActionTypes.getAccountInfo,
      updateAccountInfo: ActionTypes.updateAccountInfo
    }),
    hideLoader() {
      this.loaderShown = false
    },
    async confirm() {
      this.buttonLoading = true
      await this.updateAccountInfo(this.user)
      this.buttonLoading = false
      this.editActive = false
    },
    cancelEdit() {
      this.editActive = false
      this.getAccountInfo(this.$cookies.get('access_token'))
    },
    async openDeleteAccountModal() {
      const modal = await modalController.create({
        component: DeleteAccountModal,
        componentProps: {prefilledEmail: this.accountInfo.email}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      if (role === 'confirm') {
        this.$emit('showLoader')
      }
    },
  },
  mounted() {
    this.getAccountInfo(this.$cookies.get('access_token'))
  },
  watch: {
    accountInfo() {
      this.user = this.accountInfo
      this.hideLoader()
    }
  }
  
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "bede7adc": (_ctx.backgroundImage)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__