
import { IonContent, IonPage, IonList, IonItem, IonLoading } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import QuantitySelector from '../components/QuantitySelectorComponent.vue'
import PurchasedTokens from '../components/PurchasedTokensComponent.vue'
import StripePage from './StripePage.vue'
import LecturesList from '@/modules/events/presentation/components/LecturesListComponent.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default defineComponent({
name: 'CheckoutPage',
  components: {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    ButtonLoading,
    // IonList,
    // IonLoading,
    // LecturesList
  },
  data() {
    return {
      loaderShown: false,
      totalPriceServiceFee: 0,
      stripeItems: [{price: '', quantity: 0}],
      stripePayload: {},
      paymentHash: (Math.random() + 1).toString(36).substr(4, 10),
      discount: 0,
      // transaction: 'AOGN-MMDX-YKDN'
      // transaction: undefined
    }
  },
  computed: {
    ...mapGetters('checkout',{
      checkout: GettersTypes.checkout,
      basket: GettersTypes.basket,
      totalPrice: GettersTypes.totalPrice,
      contact: GettersTypes.getContact,
      transaction: GettersTypes.transaction
    }),
    ...mapGetters({
      auth: 'AUTH',
      isAuthorized: 'IS_AUTHORIZED'
    }),
    ...mapGetters('events',{
      events: 'EVENTS'
    }),
    lectures() {
      var json = this.events
      var lectures = json.flatMap(item => item.lectures.map(lecture => ({
        hashId: lecture.hashId,
        title: lecture.title
      })));
      var courses = json.map(el => ({
        hashId: el.hashId,
        title: el.title
      }))
      return [ ...lectures, ...courses ] 
    }
  },
  methods: {
    ...mapActions('checkout',{
      getCheckout: ActionTypes.getCheckout,
      incrementBasket: ActionTypes.incrementBasket,
      clearBasket: ActionTypes.clearBasket,
      registerPayment: ActionTypes.registerPayment,
      getTransaction: ActionTypes.getTransaction,
      setBasket: ActionTypes.setBasket
    }),
    ...mapActions({
      confirmPayment: 'CONFIRM_PAYMENT',
    }),
    ...mapActions('events',{
      getEvents: 'GET_EVENTS',
    }),
    submit() {
      const basket = this.basket.filter(item => item.itemId != 0);
      if (this.isAuthorized) {
        this.openPaymentWall()
      } else {
        this.$router.push({path: this.$t('toolbar.path_signup')})
      }
    },
    removeBasketItem(item) {
      // var newBasket = {...this.basket}
      var newBasket = this.basket.filter(el => el.itemId != item)
      this.setBasket(newBasket)
    },
    async openPaymentWall() {
      this.loaderShown = true
      await this.getTransaction({basket: this.basket, auth: this.$cookies.get('access_token')})
    }
  },
  mounted() {
    // this.getItemById()
    // const paymentId = this.$route.query.paymentId as string
    // if (paymentId) {
    //   this.confirmPayment(paymentId)
    //   this.clearUrlPath()
    // }
    if (this.events[0].hashId == '') {
      this.getEvents(this.$cookies.get('access_token'))
    }
    if (this.basket.filter(item => item.itemId != 0).length == 0) {
      this.$router.push({path: this.$t('toolbar.path_events')})
    }
  },
  watch: {
    transaction() {
      this.loaderShown = false
    },
    basket: {
      handler(to, from) {
        if (this.basket.length == 1) {
          this.$router.go(-1)
        }
      },
      deep: true
    },
    '$route': {
      handler(val, oldVal) {
        if (this.$route.query.platba) {
          this.openPaymentWall()
        }
      },
      deep: true
    },
  }
});
