import { Blog } from '../types'


export type State = {
  blog: [Blog],
  available: boolean
};

export const state = (): State => ({
  blog: [{
    id: 0,
    creationTime: "",
    title: "",
    content: "",
    additionalContent: "",
    type: "",
    level: "",
    postedBy: ""
  }],
  available: true
})
