import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2382f6d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 2,
  class: "textarea-iframe"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["alt"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 2,
  class: "textarea-iframe"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["alt"]
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_upload_avatar = _resolveComponent("upload-avatar")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_icon_badge = _resolveComponent("icon-badge")!
  const _component_level_badge = _resolveComponent("level-badge")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ExpandableText = _resolveComponent("ExpandableText")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: _normalizeClass(_ctx.edit ? 'edit-border' : '')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_card_header, { class: "image desktop" }, {
          default: _withCtx(() => [
            (this.showSkeleton || this.skeletonImage)
              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                  key: 0,
                  animated: "",
                  style: {"width":"100%","height":"100%","min-height":"100px","border-radius":"0px","margin-top":"5px"}
                }))
              : (this.edit && _ctx.post.type == _ctx.BlogTypes.ARTICLE)
                ? (_openBlock(), _createBlock(_component_upload_avatar, {
                    key: 1,
                    image: this.post.id ? _ctx.getImage : undefined,
                    name: _ctx.post.id,
                    upload: this.uploadImage,
                    onSuccess: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('confirm'))),
                    onError: _cache[1] || (_cache[1] = ($event: any) => (this.isLoadingConfirm = false))
                  }, null, 8, ["image", "name", "upload"]))
                : (this.edit && _ctx.post.type == _ctx.BlogTypes.PODCAST)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_textarea, {
                        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editPost.additionalContent = $event.target.value)),
                        value: _ctx.editPost.additionalContent,
                        placeholder: "Spotify iFrame",
                        autoGrow: "true"
                      }, null, 8, ["value"])
                    ]))
                  : (_ctx.post.type == _ctx.BlogTypes.PODCAST)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        innerHTML: _ctx.spotifyFix,
                        class: "spotify_container"
                      }, null, 8, _hoisted_3))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 4,
                        style: _normalizeStyle({'background-image': 'url(' + _ctx.getImage + ')'}),
                        alt: 'blog_post_'+_ctx.post.id
                      }, null, 12, _hoisted_4))
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                (this.showSkeleton)
                  ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                      key: 0,
                      animated: "",
                      style: {"width":"42px","height":"42px","border-radius":"30px","margin-top":"5px"}
                    }))
                  : (_openBlock(), _createBlock(_component_ion_avatar, { key: 1 }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          alt: "",
                          src: _ctx.getAvatar
                        }, null, 8, _hoisted_5)
                      ]),
                      _: 1
                    })),
                _createElementVNode("div", {
                  class: _normalizeClass(this.edit ? 'hide-mobile' : '')
                }, [
                  (this.showSkeleton)
                    ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                        key: 0,
                        animated: "",
                        style: {"width":"100px","height":"12px","margin-left":"10px","margin-top":"10px"}
                      }))
                    : (_openBlock(), _createElementBlock("p", {
                        key: 1,
                        innerHTML: this.add ? _ctx.accountInfo.name + ' ' + _ctx.accountInfo.surname : _ctx.post.name + ' ' + _ctx.post.surname
                      }, null, 8, _hoisted_6)),
                  (this.showSkeleton)
                    ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                        key: 2,
                        animated: "",
                        style: {"width":"100px","height":"12px","margin-left":"10px","margin-top":"8px"}
                      }))
                    : (_openBlock(), _createElementBlock("p", {
                        key: 3,
                        innerHTML: _ctx.post.creationTime ? _ctx.formatDate(_ctx.post.creationTime) : _ctx.formatDate(new Date())
                      }, null, 8, _hoisted_7))
                ], 2),
                _withDirectives(_createVNode(_component_ion_row, { class: "badges" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon_badge, {
                      edit: _ctx.edit,
                      preset: this.post.type,
                      onValue: _ctx.updateType
                    }, null, 8, ["edit", "preset", "onValue"]),
                    _createVNode(_component_level_badge, {
                      edit: _ctx.edit,
                      preset: this.post.level,
                      onValue: _ctx.updateLevel
                    }, null, 8, ["edit", "preset", "onValue"]),
                    _withDirectives(_createVNode(_component_icon_badge, {
                      preset: 'edit',
                      icon: 'pencil',
                      class: "edit-button",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (this.edit = true))
                    }, null, 512), [
                      [_vShow, ['internal', 'admin'].includes(this.auth.role) && !this.edit]
                    ])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, !this.showSkeleton]
                ])
              ]),
              _: 1
            }),
            (this.showSkeleton)
              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                  key: 0,
                  animated: "",
                  style: {"width":"100%","height":"35px","margin-left":"10px","margin-top":"8px"}
                }))
              : (this.edit)
                ? (_openBlock(), _createBlock(_component_ion_textarea, {
                    key: 1,
                    onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editPost.title = $event.target.value)),
                    value: _ctx.editPost.title,
                    rows: "1",
                    autoGrow: "true",
                    class: "title",
                    placeholder: "Nadpis"
                  }, null, 8, ["value"]))
                : (_openBlock(), _createBlock(_component_ion_card_title, {
                    key: 2,
                    innerHTML: _ctx.post.title
                  }, null, 8, ["innerHTML"])),
            _createVNode(_component_ion_card_header, { class: "image mobile" }, {
              default: _withCtx(() => [
                (this.showSkeleton || this.skeletonImage)
                  ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                      key: 0,
                      animated: "",
                      style: {"width":"100%","height":"100%","min-height":"100px","border-radius":"0px","margin-top":"5px"}
                    }))
                  : (this.edit && _ctx.post.type == _ctx.BlogTypes.ARTICLE)
                    ? (_openBlock(), _createBlock(_component_upload_avatar, {
                        key: 1,
                        image: this.post.id ? _ctx.getImage : undefined,
                        name: _ctx.post.id,
                        upload: this.uploadImage,
                        onSuccess: _cache[5] || (_cache[5] = ($event: any) => (this.$emit('confirm'))),
                        onError: _cache[6] || (_cache[6] = ($event: any) => (this.isLoadingConfirm = false))
                      }, null, 8, ["image", "name", "upload"]))
                    : (this.edit && _ctx.post.type == _ctx.BlogTypes.PODCAST)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_ion_textarea, {
                            onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editPost.additionalContent = $event.target.value)),
                            value: _ctx.editPost.additionalContent,
                            placeholder: "Spotify iFrame",
                            autoGrow: "true"
                          }, null, 8, ["value"])
                        ]))
                      : (_ctx.post.type == _ctx.BlogTypes.PODCAST)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            innerHTML: _ctx.spotifyFix,
                            class: "spotify_container"
                          }, null, 8, _hoisted_9))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            style: _normalizeStyle({'background-image': 'url(' + _ctx.getImage + ')'}),
                            alt: 'blog_post_'+_ctx.post.id
                          }, null, 12, _hoisted_10))
              ]),
              _: 1
            }),
            (this.showSkeleton)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_ion_skeleton_text, {
                    animated: "",
                    style: {"width":"100%","height":"12px","margin-left":"10px","margin-top":"28px"}
                  }),
                  _createVNode(_component_ion_skeleton_text, {
                    animated: "",
                    style: {"width":"100%","height":"12px","margin-left":"10px","margin-top":"8px"}
                  }),
                  _createVNode(_component_ion_skeleton_text, {
                    animated: "",
                    style: {"width":"100%","height":"12px","margin-left":"10px","margin-top":"8px"}
                  }),
                  _createVNode(_component_ion_skeleton_text, {
                    animated: "",
                    style: {"width":"70%","height":"12px","margin-left":"10px","margin-top":"8px"}
                  })
                ]))
              : (_ctx.edit)
                ? (_openBlock(), _createBlock(_component_ion_textarea, {
                    key: 4,
                    onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.editPost.content = $event.target.value)),
                    value: _ctx.editPost.content,
                    class: "content",
                    autoGrow: "true",
                    placeholder: "Obsah"
                  }, null, 8, ["value"]))
                : (_openBlock(), _createBlock(_component_ExpandableText, {
                    key: 5,
                    post: _ctx.post
                  }, null, 8, ["post"]))
          ]),
          _: 1
        })
      ]),
      (this.edit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_ion_row, { class: "submit" }, {
              default: _withCtx(() => [
                _createVNode(_component_button_loading, {
                  class: "cancel",
                  color: "medium",
                  size: "small",
                  text: "zrušit",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => {this.$emit('close'); this.edit = false})
                }),
                _createVNode(_component_button_loading, {
                  class: "confirm",
                  color: "success",
                  size: "small",
                  text: "potvrdit",
                  isLoading: this.isLoadingConfirm,
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.confirmEdit()))
                }, null, 8, ["isLoading"])
              ]),
              _: 1
            }),
            (!this.add)
              ? (_openBlock(), _createBlock(_component_ion_row, {
                  key: 0,
                  class: "delete"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_button_loading, {
                      class: "remove",
                      color: "danger",
                      size: "small",
                      text: "smazat",
                      isLoading: this.isLoadingDelete,
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.hideEvent()))
                    }, null, 8, ["isLoading"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}