import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dd6e302"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "customize-header" }
const _hoisted_2 = {
  key: 1,
  class: "dot-carousel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_vue3_easy_data_table = _resolveComponent("vue3-easy-data-table")!

  return (_openBlock(), _createBlock(_component_vue3_easy_data_table, {
    "buttons-pagination": "",
    alternating: "",
    headers: _ctx.headers,
    items: this.data,
    loading: this.data.length ? false : this.loading,
    "search-value": this.search
  }, {
    [`header-tools`]: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (this.data.length ? !this.loading : true)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              icon: _ctx.refresh,
              class: "refresh",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (this.refreshTable()))
            }, null, 8, ["icon"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2))
      ])
    ]),
    [`item-creation_time`]: _withCtx(({ creation_time }) => [
      _createTextVNode(_toDisplayString(this.formattedTime(creation_time)), 1)
    ]),
    [`item-price`]: _withCtx((item) => [
      _createTextVNode(_toDisplayString(parseInt(item.price) * parseInt(item.quantity) + parseInt(item.service_fee) + '€'), 1)
    ]),
    [`item-scanned`]: _withCtx((item) => [
      (item.scanned)
        ? (_openBlock(), _createBlock(_component_ion_badge, {
            key: 0,
            color: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode("YES")
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_badge, { color: "danger" }, {
                default: _withCtx(() => [
                  _createTextVNode("NO")
                ]),
                _: 1
              }),
              _createVNode(_component_button_loading, {
                class: "action-button",
                color: "medium",
                text: "scan",
                onClick: ($event: any) => (this.scanTicket(item))
              }, null, 8, ["onClick"])
            ]),
            _: 2
          }, 1024))
    ]),
    _: 2
  }, 1032, ["headers", "items", "loading", "search-value"]))
}