import { MutationTypes } from '@/modules/tickets/business/mutations'
import axios from 'axios'
import { useToast } from "vue-toastification";

export enum ActionTypes {
  getLectures = 'GET_LECTURES',
  assignTickets = 'ASSIGN_TICKETS',
  saveContent = 'SAVE_CONTENT',
  getContent = 'GET_CONTENT'
}


export const actions = {
  async [ActionTypes.getLectures]({ commit }, auth) {
    const path = process.env.VUE_APP_BASE_URL+'/lectures/' + auth;
    console.log('aaa')
    await axios.get(path).then((response) => {
      commit(MutationTypes.courses, response.data)
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      }
      commit(MutationTypes.courses, [])
    });
  },
  async [ActionTypes.assignTickets]({ commit }, {ticket_token,token}) {
    const path = process.env.VUE_APP_BASE_URL+'/assign_ticket/' + ticket_token + '/' + token;
    await axios.get(path).then((response) => {
      commit(MutationTypes.courses, response.data)
      useToast().success("Your ticket is assigned!")
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      } else if (error.response.status == 402) {
        useToast().error("There's not any ticket left to assign!")
      } else if (error.response.status == 403) {
        useToast().error("You cannot assign the ticket to yourself!")
      } else if (error.response.status == 404) {
        useToast().error("Ticket not found!")
      } else {
        useToast().error("Something went wrong!\nRefresh the window and try again.")
      }
      commit(MutationTypes.courses, [])
    });
  },
  async [ActionTypes.saveContent]({ commit }, {auth, content, hashId}) {
    const path = process.env.VUE_APP_BASE_URL+'/internal/update_lecture/' + auth;
    const data = {hashId: hashId, content: JSON.stringify(content)}
    await axios.post(path, data).then((response) => {
      useToast().success("Lecture updated!")
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      }
      commit(MutationTypes.courses, [])
    });
  },
  async [ActionTypes.getContent]({ commit }, {auth, hashId}) {
    const path = process.env.VUE_APP_BASE_URL+'/lecture/' + hashId + '/' + auth;
    await axios.get(path).then((response) => {
      commit(MutationTypes.content, response.data)
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      }
      commit(MutationTypes.content, [])
    });
  },
}

