
    import {
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        modalController,
    } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import CheckoutPage from '@/modules/checkout/presentation/views/CheckoutPage.vue'
    export default defineComponent({
        name: 'CheckoutPageModal',
        components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, CheckoutPage },
        methods: {
          cancel() {
            return modalController.dismiss(null, 'cancel');
          },
          confirm() {
            return modalController.dismiss(this, 'confirm');
          },
        },
    });
