
import { mapActions } from 'vuex'
import { ActionTypes } from '../../../business'
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonIcon, IonInput, IonLabel, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
    name: 'ForgottenPasswordModal',
    props: {
        prefilledEmail: {
            type: String
        }
    },
    data() {
      return {
        email: this.prefilledEmail
      }
    },
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonButtons,
      IonIcon,
      IonInput,
      IonLabel
    },
    setup() {
      return {
        closeOutline
      }
    },
    methods: {
    ...mapActions({
      sendRenewalEmail: ActionTypes.sendRenewalEmail,
    }),
    cancel() {
      this.$emit('cancel')
      return modalController.dismiss(null, 'cancel');
    },
    confirm() {
      return modalController.dismiss(this, 'confirm');
    },
    submit() {
      this.sendRenewalEmail(this.email)
      return modalController.dismiss(this, 'confirm');
    },
  },
});
