
import { IonItem, IonList, IonLabel, IonIcon, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { ellipsisVertical } from "ionicons/icons";
import { QrcodeStream } from 'vue3-qrcode-reader'
import AssignTicketModal from './modal/AssignTicketModal.vue'

export default defineComponent({
name: 'ToolButton',
component: {
  IonItem, 
  IonList,
  IonLabel,
  IonIcon
},
props: {
  item: {
    type: Object
  },
  forceClose: {
    type: Boolean
  }
},
data() {
  return {
    open: false
  }
},
setup() {
  return {
    ellipsisVertical
  }
},
methods: {
  openTools() {
    if (!this.open) {
      setTimeout(() => {
        this.open = true
      }, 20);
    }
  },
  async openAssignTicketModal(email) {
      const modal = await modalController.create({
        component: AssignTicketModal,
        componentProps: {email: this.item.email}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
    },
},
watch: {
  forceClose() {
    if (this.open) {
      this.open = false
    }
  },
}

});
