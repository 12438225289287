import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import EventsPage from '../modules/events/presentation/views/EventsPage.vue'
import TicketsPage from '../modules/tickets/presentation/views/TicketsPage.vue'
import CheckoutPage from '../modules/checkout/presentation/views/CheckoutPage.vue'
import StripePage from '../modules/checkout/presentation/views/StripePage.vue'
import SignInPage from '../modules/auth/presentation/views/SignInPage.vue'
import SignUpPage from '../modules/auth/presentation/views/SignUpPage.vue'
import CreateAccountPage from '../modules/auth/presentation/views/CreateAccountPage.vue'
import SignOutPage from '../modules/auth/presentation/views/SignOutPage.vue'
import UserDetailsPage from '../modules/auth/presentation/views/UserDetailsPage.vue'
import AccountInfoPage from '../modules/auth/presentation/views/AccountInfoPage.vue'
import ResetPasswordPage from '../modules/auth/presentation/views/ResetPasswordPage.vue'
import PortfolioPage from '../modules/portfolio/presentation/views/PortfolioPage.vue'
import LegalPage from '../modules/portfolio/presentation/views/LegalPage.vue'
import LegalDataPage from '../modules/portfolio/presentation/views/LegalDataPage.vue'
import ScannerPage from '../modules/internal/presentation/views/ScannerPage.vue'
import TablePage from '../modules/internal/presentation/views/TablePage.vue'
import BlogPage from '../modules/blog/presentation/views/BlogPage.vue'
import ContentPage from '../modules/tickets/presentation/views/ContentPage.vue'
import LecturesPage from '../modules/tickets/presentation/views/LecturesPage.vue'
import PaymentPage from '../modules/checkout/presentation/views/PaymentPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/portfolio',
    redirect: '/'
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Tak Trochen',
    component: EventsPage,
  },
  {
    path: '/prihlaseni',
    name: 'Přihlásit se',
    component: SignInPage,
  },
  {
    path: '/odhlaseni',
    name: 'Odhlásit se',
    component: SignOutPage,
  },
  // {
  //   path: '/vytvorit-ucet',
  //   name: 'Vytvořit účet',
  //   component: SignUpPage,
  // },
  {
    path: '/vytvorit-ucet',
    name: 'Vytvořit účet',
    component: CreateAccountPage,
  },
  {
    path: '/user-details',
    name: 'User details',
    component: UserDetailsPage,
  },
  {
    path: '/kurzy',
    name: 'Kurzy',
    component: EventsPage
  },
  {
    path: '/kosik',
    name: 'Košík',
    component: CheckoutPage,
    meta: {
      back: true,
    }
  },
  {
    path: '/tokens',
    name: 'Tokens',
    component: CheckoutPage
  },
  {
    path: '/ucet',
    name: 'Váš účet',
    component: AccountInfoPage,
  },
  {
    path: '/forgotten-password',
    name: 'Forgotten Password',
    component: ResetPasswordPage,
  },
  {
    path: '/internal/scanner',
    name: 'QR Scanner',
    component: ScannerPage,
  },
  {
    path: '/internal/admin/table',
    name: '',
    component: TablePage,
  },
  {
    path: '/legal/vseobecne-obchodni-podminky/',
    name: 'Všeobecné obchodní podmínky',
    component: LegalPage,
    meta: {
      back: true,
    }
  },
  {
    path: '/legal/ochrana-osobnich-udaju',
    name: 'Ochrana osobních údajů',
    component: LegalDataPage,
    meta: {
      back: true,
    }
  },
  {
    path: '/blog/',
    name: 'Blog',
    component: BlogPage,
  },
  {
    path: '/newsletter/',
    name: 'Newsletter',
    component: ContentPage,
  },
  {
    path: '/lekce/:hashId',
    name: 'Lekce',
    component: ContentPage,
    meta: {
      back: true,
    }
  },
  {
    path: '/lekce/',
    name: 'Moje lekce',
    component: LecturesPage,
  },
  {
    path: '/payment/',
    name: '',
    component: PaymentPage,
    meta: {
      hideToolbar: true,
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
