import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1813b480"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%","height":"100px","background-color":"var(--ion-color-primary-shade)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fab_menu = _resolveComponent("fab-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, { mode: "md" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.$route.meta.hideToolbar)
          ? (_openBlock(), _createBlock(_component_fab_menu, {
              key: 0,
              class: "toolbar",
              isAuthorized: this.isAuthorized,
              forceClose: this.closeFab,
              onFabClick: _cache[0] || (_cache[0] = ($event: any) => (this.isMenuOpen = !this.isMenuOpen))
            }, null, 8, ["isAuthorized", "forceClose"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        style: {"height":"100%"},
        onClick: _cache[2] || (_cache[2] = ($event: any) => (this.routerClicked()))
      }, [
        _createVNode(_component_ion_router_outlet, {
          class: _normalizeClass(["transition", this.isMenuOpen ? 'blur' : ''])
        }, null, 8, ["class"]),
        (_ctx.$router.currentRoute.value.path != _ctx.$t('toolbar.path_checkout') && _ctx.basket.filter(el => el.itemId != '').length)
          ? (_openBlock(), _createBlock(_component_ion_fab, {
              key: 0,
              slot: "fixed",
              vertical: "bottom",
              horizontal: "end"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_badge, {
                  color: "secondary",
                  style: {"left":"-5px","z-index":"3","position":"absolute"},
                  mode: "ios"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.basket.filter(el => el.itemId != '').length), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_fab_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isAuthorized ? _ctx.$router.push({path: _ctx.$t('toolbar.path_checkout')}) : _ctx.$router.push({path: _ctx.$t('toolbar.path_signup')})))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.cartOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}