import { MutationTypes } from '@/modules/events/business/mutations'
import axios from 'axios'
import { useToast } from "vue-toastification";

export enum ActionTypes {
  getEvents = 'GET_EVENTS',
  getNextEvent = "GET_NEXT_EVENT",
  openRegistration = 'OPEN_REGISTRATION',
  registerToEvent = 'REGISTER_TO_EVENT',
}

export const actions = {
  async [ActionTypes.getEvents]({ commit }, auth) {
    const path = process.env.VUE_APP_BASE_URL+'/events/'+auth;
    axios.get(path).then((response) => {
      commit(MutationTypes.getEvents, response.data)
    })
  },
  async [ActionTypes.getNextEvent]({ commit }, auth) {
    const path = process.env.VUE_APP_BASE_URL+'/next_event/'+ (auth ? auth : 'none');
    axios.get(path,).then((response) => {
      commit(MutationTypes.getNextEvent, response.data)
    })
  },
  async [ActionTypes.openRegistration]({ commit }, { eventId, email, instagram, noInstagram }) {
    const path = process.env.VUE_APP_BASE_URL+'/open_registration/'+eventId
    const data = { eventId, email, instagram, noInstagram }
    await axios.post(path, data).then((response) => {
      useToast().success("You've been registered!")
      commit(MutationTypes.openRegistration, eventId)
    }).catch((error) => {
      commit(MutationTypes.cancelRegistration, eventId)
      useToast().error("Something went wrong!\nRefresh the window and try again.")
    })
  },
  async [ActionTypes.registerToEvent]({ commit }, { eventId, token }) {
    const path = process.env.VUE_APP_BASE_URL+'/register_to_event/'+eventId
    const data = { eventId, token }
    await axios.post(path, data).then((response) => {
      commit(MutationTypes.openRegistration, eventId)
      useToast().success("You've been registered!")
      if (response.data.state == 'PRE_APPROVED') {
        setTimeout(() => {
          commit(MutationTypes.preApprovedToEvent, eventId)
          useToast().warning("You've been pre-approved!")
        }, 3000)
      }
    }).catch((error) => {
      useToast().error("Something went wrong!\nRefresh the window and try again.")
      commit(MutationTypes.cancelRegistration, eventId)
    })
  },
}

