import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e1aa6a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "add-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_event = _resolveComponent("edit-event")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_event_card = _resolveComponent("event-card")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (this.isInternalRole())
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (this.addNewEvent)
                  ? (_openBlock(), _createBlock(_component_edit_event, {
                      key: 0,
                      class: "add-event desktop-event",
                      onClose: _cache[0] || (_cache[0] = ($event: any) => (this.addNewEvent = false))
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_icon, {
                        style: {"height":"60px","width":"60px","opacity":"0.5"},
                        icon: _ctx.addCircleOutline,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNewEvent = true))
                      }, null, 8, ["icon"])
                    ]))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, {
            lines: "none",
            class: "desktop"
          }, {
            default: _withCtx(() => [
              (this.loaderShown)
                ? (_openBlock(), _createBlock(_component_event_card, {
                    key: 0,
                    loading: true
                  }))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.events, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "upcoming-events",
                      key: item
                    }, [
                      (item?.edit)
                        ? (_openBlock(), _createBlock(_component_edit_event, {
                            key: 0,
                            event: item,
                            onClose: ($event: any) => (this.events[this.events.findIndex(x => x.hashId == item.hashId)].edit = false)
                          }, null, 8, ["event", "onClose"]))
                        : (_openBlock(), _createBlock(_component_event_card, {
                            key: 1,
                            event: item,
                            loading: false,
                            onEdit: ($event: any) => (this.events[this.events.findIndex(x => x.hashId == item.hashId)].edit = true)
                          }, null, 8, ["event", "onEdit"]))
                    ]))
                  }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}