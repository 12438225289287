import { MutationTypes } from './mutations'
import axios from 'axios'
import { useToast } from "vue-toastification";

export enum ActionTypes {
  getCheckout = 'GET_CHECKOUT',
  setBasket = 'SET_BASKET',
  incrementBasket = 'INCREMENT_BASKET',
  clearBasket = 'CLEAR_BASKET',
  registerPayment = 'REGISTER_PAYMENT',
  loaderShown = 'LOADER_SHOWN',
  getTokens = 'GET_TOKENS',
  getTransaction = 'GET_TRANSACTION'
}


export const actions = {
  async [ActionTypes.getCheckout]({ commit }, pageId) {
    const path = process.env.VUE_APP_BASE_URL+'/checkout/'+pageId;
    await axios.get(path,).then((response) => {
      commit(MutationTypes.checkout, response.data)
    })
  },
  async [ActionTypes.setBasket]({ commit }, basket: [{ itemId, price }]) {
    commit(MutationTypes.setBasket, basket)
    commit(MutationTypes.transaction, undefined)
  },
  async [ActionTypes.incrementBasket]({ commit }, { itemId, price }) {
    commit(MutationTypes.incrementBasket, { itemId, price })
  },
  async [ActionTypes.clearBasket]({ commit }) {
    commit(MutationTypes.clearBasket)
  },
  async [ActionTypes.registerPayment]({ commit }, data) {
    const path = process.env.VUE_APP_BASE_URL+'/register-payment/';
    await axios.post(path, data).then((response) => {
      commit(MutationTypes.setContact, {email: response.data.email, instagram: response.data.instagram})
      console.log('checkout','Payment registered')
      
    }).catch((error) => {
      useToast().error("Something went wrong!\nRefresh the window and try again.")
    })
  },
  async [ActionTypes.loaderShown]({ commit }, shown) {
    commit(MutationTypes.loaderShown, shown)
  },
  async [ActionTypes.getTokens]({ commit }, auth) {
    const path = process.env.VUE_APP_BASE_URL+'/tokens/' + auth;
    axios.get(path).then((response) => {
      commit(MutationTypes.upadteTokens, response.data)
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      }
      commit(MutationTypes.upadteTokens, [])
    });
  },
  async [ActionTypes.getTransaction]({ commit }, {basket, auth}) {
    const path = process.env.VUE_APP_BASE_URL+'/pay/' + auth;
    basket = basket.filter(el => el.itemId != '')
    axios.post(path, basket).then((response) => {
      commit(MutationTypes.transaction, response.data.transaction)
    }).catch(function (error) {
      if (error.response.status == 401) {
        console.log("ticktes","unauthorized")
      }
      commit(MutationTypes.transaction, undefined)
    });
  },
}
