import { MutationTypes } from './mutations'
import axios from 'axios'

export enum ActionTypes {
    getBlogBatch = 'GET_BLOG_BATCH',
    getBlog = 'GET_BLOG'
}


export const actions = {
    async [ActionTypes.getBlogBatch]({ commit }, batch) {
        const path = process.env.VUE_APP_BASE_URL+'/blog/batch/'+batch;
        axios.get(path).then((response) => {
            if (response.status == 200) {
                commit(MutationTypes.getBlog, response.data)
                commit(MutationTypes.available, true)
            } else if (response.status == 201) {
                commit(MutationTypes.getBlog, response.data)
                commit(MutationTypes.available, false)
            }
        }).catch((error) => {
          console.log('Failed! Get block')  
        })
    },
    async [ActionTypes.getBlog]({ commit }, id) {
        const path = process.env.VUE_APP_BASE_URL+'/blog/'+id;
        axios.get(path).then((response) => {
          commit(MutationTypes.getBlog, response.data)
        }).catch((error) => {
            if (error.response.status == 404) {
                commit(MutationTypes.available, false)
            }
        })
    },
}
