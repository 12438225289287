
import { IonContent, IonPage, IonHeader, IonCard, IonTitle } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { GettersTypes } from '../../business'
import SignIn from '@/modules/auth/presentation/components/SignInComponent.vue'

const __default__ = defineComponent({
name: 'SignInPage',
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonCard,
    IonTitle,
    SignIn
  },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized
    }),
    backgroundImage() {
      return 'url(' + process.env.VUE_APP_ASSETS_URL + '/icon/red-pattern.png)'
    },
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "718e5181": (_ctx.backgroundImage)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__