
import { mapActions } from 'vuex'
import { ActionTypes } from '../../../business'
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonFabButton, IonIcon, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, logInOutline } from 'ionicons/icons';
import { Site } from '../../../enums'

export default defineComponent({
    name: 'UserExistsModal',
    props: {
        prefilledEmail: {
            type: String
        },
        site: {
            type: String
        }
    },
    data() {
      return {
        email: this.prefilledEmail,
        Site
      }
    },
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonButtons,
      IonFabButton,
      IonIcon,
    },
    setup() {
      return {
        closeOutline,
        logInOutline
      }
    },
    methods: {
    ...mapActions({
      sendRenewalEmail: ActionTypes.sendRenewalEmail,
    }),
    cancel() {
      this.$emit('cancel')
      return modalController.dismiss(null, 'cancel');
    },
    confirm() {
      return modalController.dismiss(this, 'confirm');
    },
    submit() {
      this.sendRenewalEmail(this.email)
      return modalController.dismiss(this, 'confirm');
    },
  },
});
