import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (!_ctx.edit)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "badge",
        style: _normalizeStyle('background-color: var(--ion-color-' + (_ctx.TypeColor[_ctx.preset]) + ')')
      }, [
        _createVNode(_component_ion_icon, {
          icon: _ctx.getIcon(_ctx.TypeIcon[_ctx.preset]),
          color: ['headset'].includes(_ctx.TypeIcon[_ctx.preset]) ? 'light' : 'dark'
        }, null, 8, ["icon", "color"])
      ], 4))
    : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_select, {
                modelValue: _ctx.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
                interface: "popover"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select_option, {
                    value: this.BlogTypes.PODCAST
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Podcast")
                    ]),
                    _: 1
                  }, 8, ["value"]),
                  _createVNode(_component_ion_select_option, {
                    value: this.BlogTypes.ARTICLE
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Článek")
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}