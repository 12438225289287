export const CopyTakTrochen = {
  locale: 'en',
  messages: {
    en: {
      common: {
        confirm: "Potvrdit",
        cancel: "Zrušit",
        yes: "ano",
        no: "ne",
      },
      toolbar: {
        title_portfolio: "Domů",
        path_portfolio: "/",
        title_events: "Kurzy",
        path_events: "/kurzy",
        title_blog: "Blog",
        path_blog: "/blog",
        title_tickets: "Lekce",
        path_tickets: "/lekce",
        title_checkout: "Košík",
        path_checkout: "/kosik",
        title_account: "Můj účet",
        path_account: "/ucet",
        title_signin: "Přihlásit se",
        path_signin: "/prihlaseni",
        title_signup: "Vytvořit účet",
        path_signup: "/vytvorit-ucet",
        title_signout: "Odhlásit",
        path_signout: "/odhlaseni",
        title_scanner: "QR Scanner",
        path_scanner: "/internal/scanner",
        title_admin: "Admin",
        path_admin: "/internal/admin/table",
      },
      auth: {
        email: "Email",
        password: "Heslo",
        name: "Jméno",
        surname: "Příjmení",
        referral: "What brought you here?",
        instagram: "Instagram",
        document: "ID/NIE/DNI #",
        phone: "Telefonní číslo",
        street: "Ulice",
        city: "Město",
        postal_code: "PSČ",
        short_instagram: "ID Numb",
        terms: "Podmínky použití",
        terms_text: "<h1>Privacy Policy for Tak Trochen</h1>",
        optional: "volitelné",
        account: {
          title: "Tvůj účet",
          subtitle: "Děkujeme, že udržuješ tvoje údaje aktuální.",
          details: "Info",
          email_change: " *Napište nám ohledně změny emailu",
          delete_account: "Smazat účet"
        },
        signup: {
          title: "Willkommen bei Tak Trochen",
          subtitle: "Skvělé, že jsi tady! Tak Trochen je komunita pro všechny, kdo se učí šprechtit.",
          subtitle_details: "Skvělé, že jsi tady! Tak Trochen je komunita pro všechny, kdo se učí šprechtit.",
          details: "Osobní údaje",
          credentials: "Přihlašovací údaje",
          password_validation: " *alespoň 8 znaků",
          email_validation: "*nesprávný formát",
          email_exists: "*email již existuje!",
          button_submit: "Potvrdit",
          modal_user_exists: {
            modal_title: "User exists!",
            title: "Email alredy exists<br>at *site*!",
            button: "Sign in instead"
          }
        },
        signin: {
          title: "Přihlásit se",
          subtitle: "do komunity pro všechny, kdo se učí šprechtit.",
          forgotten_password: "Zapomenuté heslo",
          invalid_credentials: "Nesprávné údaje!",
          create_account: "Vytvořit účet",
          button: "Přihlásit se",
        },
        signout: {
          message: "Bis bald! &#x1F495;"
        },
        forgotten_password: {
          modal_title: "Zapomenuté heslo",
          title: "Was ist los?",
          subtitle: "Pošleme ti email s odkazem, na kterém si můžeš obnovit heslo.",
          email: "Email",
          button_send: "Odeslat"
        },
        reset_password: {
          modal_title: "Obnovit heslo",
          password: "Nové heslo",
          password_validation: " *alespoň 8 znaků"
        },
        delete_account: {
          modal_title: "Smazat účet",
          title: "Přihlašte se",
          subtitle: "pro smazání účtu.",
          button_delete: "Smazat"
        }
      },
      portfolio: {
        heading_first: "Established in 2019, Buena Onda Social Club is a Private Members club located in the heart of Gracia.",
        heading_second: "This is what happens when a cultural hub and a speak easy meet.",
        link_youtube: "https://www.youtube.com/embed/XHjHp9xg0sw",
        divider: {
          first: "Upcoming Event",
          second: "Get Involved",
          third: "Gallery",
          fourth: "Core Values",
          fifth: "Follow Us"
        },
        content_first: [
          {
            id: 1,
            title: "Share",
            subtitle: "Safe",
            text: "Are you a collective, association or group of creatives that want to share your art with the world? We offer a safe space for an international community of creatives. If you have a project in mind, contact us."
          },
          {
            id: 2,
            title: "Celebrate",
            subtitle: "Bespoke",
            text: "Do you want to plan something special behind closed doors? Our bespoke tailored solution range from private celebrations to special occasions and team building activities. Contact us."
          },
          {
            id: 3,
            title: "Create",
            subtitle: "Your narrative",
            text: "Do you need a versatile space to create impactful content? Photoshoot, social media, recording dj sets? We have an amazing space, come make it your own. Contact us."
          }
        ],
        content_second: [
          {
            id: 4,
            title: "It's all about YOU",
            subtitle: "A Polyvalent space",
            text: "What keeps us thriving; we pride ourselves on creating a safe and fun space for everyone regardless of age, gender, sexuality or race."
          },
          {
            id: 5,
            title: "Endless Possibilities",
            subtitle: "If you can dream it, you can do it.",
            text: "Bring us your wildest ideas, your maddest fantasies, and your burning questions about life.\nWe’ll make them happen. Go wild!"
          },
          {
            id: 6,
            title: "Reduce, Reuse, Recycle",
            subtitle: "The world is ours",
            text: "We take conscous decisions when it comes to the enviornment. We do simple acts, recycle waste propperly, repurpose things for a Second Life and aim for zero waste."
          }
        ],
        contact_us: {
          first: 'https://www.facebook.com/boscbcn',
          second: 'https://www.instagram.com/buenaondasocialclub/',
          third: 'https://www.twitch.tv/boscbcn',
        },
        copyright: "Copyright&nbsp;©&nbsp;2023&nbsp;Tak&nbsp;Trochen. Všechna&nbsp;práva&nbsp;vyhrazena."
      },
      events: {
        divider: {
          past_events: "Past Events"
        },
        more_details: "Zakoupit jednotlivé lekce",
        add_to_calendar: "",
        message: {
          not_registered: "*You need to register first.",
          purchased: "You have purchased your entrances!",
          pre_approved: "",
          registartion_pending: "",
          purchase: "Zakoupit kurz",
          purchase_more: "Purchase +1",
          no_reg: "Tickets Coming Soon!",
        },
        modal: {
          title_register: "Purchase",
          subtitle_signin: "Do you have an account?",
          subtitle_signup: "Your first time here?"
        },
        progress_bar: {
          step_one: "Purchase",
          step_two: "Check-In",
          step_three: "Experience",
        },
        open_registration: {
          email: "Your email",
          instagram: "Password",
          referral: "What brought you here?",
          short_instagram: "ID/NIE/DNI #",
          short_no_instagram: "Password",
          button_register: "Register"
        }
      },
      tickets: {
        no_tickets: {
          title: "You don't have any entrances yet!",
          subtitle: "Check out the next event!"
        },
        divider: {
          past_tickets: "Past Events"
        },
        buttons: {
          first: "Info Package",
          second: "WhatsApp Group",
          third: "Invite Guests"
        }
      },
      checkout: {
        currency: "€",
        service_fee: "Service Fee",
        total_price: "Total",
        button_confirm: "Potvrdit",
        button_disabled: "Zrušit",
        button_pay: "Pay now!"
      },
      internal: {
        assign_ticket: "Assign Ticket",
        block_user: "Block User"
      }
    }
  }
}