export enum GettersTypes {
    search = 'SEARCH',
    table = 'TABLE',
    scanData = 'SCAN_DATA',
    scanStatus = 'SCAN_STATUS',
    loading = 'LOADING',
    registrations = 'REGISTRATIONS',
    payments = 'PAYMENTS',
    users = 'USERS',
    checkoutPages = 'CHECKOUT_PAGES',
    edit = 'EDIT'
}

export const getters = {
    [GettersTypes.search]: (data) => data?.search,
    [GettersTypes.table]: (data) => data?.table,
    [GettersTypes.scanData]: (data) => data?.scanData,
    [GettersTypes.scanStatus]: (data) => data?.scanStatus.status,
    [GettersTypes.loading]: (data) => data?.loading,
    [GettersTypes.registrations]: (data) => data?.registrations,
    [GettersTypes.payments]: (data) => data?.payments,
    [GettersTypes.users]: (data) => data?.users,
    [GettersTypes.checkoutPages]: (data) => data?.checkoutPages,
    [GettersTypes.edit]: (data) => data?.editToggle,
}
