
import { IonPage, IonItem, IonLabel, IonInput, IonIcon, IonLoading, modalController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../business'
import { Site } from '../../enums'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import UserExistsModal from '../components/modal/UserExistsModal.vue'
import { informationCircleOutline } from 'ionicons/icons';


const __default__ = defineComponent({
name: 'CreateAccountPage',
  components: {
    IonPage,
    IonItem,
    IonIcon,
    IonLabel,
    IonInput,
    IonLoading,
    ButtonLoading
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
        name: "",
        surname: "",
        city: "",
        street: "",
        postalCode: "",
      },
      loaderShown: false,
      submitted: false,
      Site
    }
  },
  setup() {
    return {
      informationCircleOutline
    }
  },
  computed: {
    ...mapGetters({
      auth: GettersTypes.auth,
      isAuthorized: GettersTypes.isAuthorized,
      userExists: GettersTypes.userExists,
      prefillData: GettersTypes.prefillData
    }),
    backgroundImage() {
      return 'url(' + process.env.VUE_APP_ASSETS_URL + '/icon/red-pattern.png)'
    },
  },
  methods: {
    ...mapActions({
      createAccount: ActionTypes.createAccount,
      checkEmail: ActionTypes.checkEmail,
      getPrefillData: ActionTypes.getPrefillData
    }),
    async submit() {
      this.submitted = true
      const validInput = this.validateInput()
      if (validInput) {
        this.loaderShown = true
        await this.createAccount(this.user)
        this.loaderShown = false
        if (!this.userExists) {
          this.$emit('signedUp')
          this.$router.push({ path: this.$t('toolbar.path_checkout'), query: {platba: true} })
        }
      }
    },
    validateEmail(email) {
      var valid = String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      this.emailDanger = valid ? '' : 'danger'
      return valid
    },
    validateInput() {
      if (this.submitted) {
        return (
          this.user.name && 
          this.user.surname && 
          this.user.city && 
          this.user.street && 
          this.user.postalCode && 
          this.user.postalCode && 
          this.user.password.length >= 8 && 
          this.validateEmail(this.user.email))
      } else {
        return true
      }
    },
    checkExistingEmail() {
      this.validateEmail(this.user.email)
      if (this.email) {
        this.checkEmail(this.user.email)
      }
    },
    async openUserExistsModal() {
      const modal = await modalController.create({
        component: UserExistsModal,
        componentProps: {prefilledEmail: this.user.email, site: this.userExists}
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      // if (role === 'confirm') {
      //   this.$emit('showLoader')
      // }
    },
    async fillData(hashId) {
      this.loaderShown = true
      this.submitted = true
      await this.getPrefillData(hashId)
      this.email = this.prefillData.email
      this.name = this.prefillData.name
      this.surname = this.prefillData.surname
      this.street = this.prefillData.street
      this.city = this.prefillData.city
      this.postalCode = this.prefillData.postalCode
      this.validateInput()
      this.loaderShown = false
    },
    redirectSignIn() {
      var query = this.$route.query
      query.email = this.user.email
      this.$router.push({path: this.$t('toolbar.path_signin'), query: query})
    }
  },
  mounted() {
    const hashId = this.$route.query.hashId as string
    if (hashId) {
      this.fillData(hashId)
    }
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2ce27a26": (_ctx.backgroundImage)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__