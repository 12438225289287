import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isCurrentPath(_ctx.$t('toolbar.path_admin')))
      ? (_openBlock(), _createBlock(_component_ion_item, {
          key: 0,
          class: "nav-internal",
          lines: "none"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              size: "small",
              color: this.$route.query.name == _ctx.Tables.USERS || !this.$route.query.name ? 'medium' : 'light',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (this.navigateTable(_ctx.Tables.USERS)))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Uživatelé")
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_ion_button, {
              size: "small",
              color: this.$route.query.name == _ctx.Tables.PAYMENTS ? 'medium' : 'light',
              onClick: _cache[1] || (_cache[1] = ($event: any) => (this.navigateTable(_ctx.Tables.PAYMENTS)))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Platby")
              ]),
              _: 1
            }, 8, ["color"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isCurrentPath(_ctx.$t('toolbar.path_admin')))
      ? (_openBlock(), _createBlock(_component_ion_item, {
          key: 1,
          class: _normalizeClass(["search", this.expandSearchPhone ? 'expand-search' : '']),
          lines: "none"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_input, {
              modelValue: this.search,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.search) = $event)),
              placeholder: "Search"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_ion_icon, {
              icon: this.search ? _ctx.closeOutline : _ctx.searchOutline,
              slot: "start",
              color: this.search ? 'danger' : 'medium',
              class: _normalizeClass(this.search ? 'clear-search' : ''),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (this.search ? this.clearSearch() : this.expandSearchPhone = !this.expandSearchPhone))
            }, null, 8, ["icon", "color", "class"])
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.showEdit)
      ? (_openBlock(), _createBlock(_component_ion_item, {
          key: 2,
          class: "edit-internal",
          lines: "none"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_ion_toggle, {
                modelValue: _ctx.isEdit,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isEdit) = $event)),
                onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleEdit($event)))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode("Upravit")
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}