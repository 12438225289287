import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79556b56"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "",
  slot: "content"
}
const _hoisted_2 = { style: {"margin":"0 7px 7px 10px","padding-top":"20px","padding-bottom":"12px","width":"100%","display":"block"} }
const _hoisted_3 = {
  slot: "header",
  class: "lecture"
}
const _hoisted_4 = {
  style: {"display":"flex","opacity":"0.5","justify-content":"center"},
  class: "dropdown"
}
const _hoisted_5 = {
  readonly: "",
  slot: "content",
  style: {"margin-top":"10px"}
}
const _hoisted_6 = {
  slot: "end",
  class: "mobile",
  style: {"text-align":"center"}
}
const _hoisted_7 = {
  slot: "end",
  class: "desktop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_button_loading = _resolveComponent("button-loading")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_title, {
      style: {"margin":"0 20px 10px 20px"},
      innerHTML: _ctx.$t('events.more_details')
    }, null, 8, ["innerHTML"]),
    _createVNode(_component_ion_list, { lines: "inset" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.event.lectures, (lecture, index) => {
          return (_openBlock(), _createBlock(_component_ion_item, {
            class: "detail",
            style: {"display":"block"},
            lines: (index + 1) == _ctx.event.lectures.length && 'none',
            key: lecture
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.event.lectures.length)
                  ? (_openBlock(), _createBlock(_component_ion_accordion_group, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_accordion, {
                          value: "first",
                          expand: "inset"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_ion_text, {
                                class: "ion-text-wrap",
                                style: {"display":"block"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(lecture.title), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_ion_label, { style: {"cursor":"pointer"} }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Obsah lekce")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_icon, {
                                  style: {"margin-top":"2px","margin-left":"3px","transition":"200ms"},
                                  icon: _ctx.chevronDownOutline
                                }, null, 8, ["icon"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_ion_text, {
                                innerHTML: lecture.description
                              }, null, 8, ["innerHTML"])
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  (lecture.purchased)
                    ? (_openBlock(), _createBlock(_component_button_loading, {
                        key: 0,
                        text: "Zakoupeno",
                        size: "small",
                        icon: "checkmarkOutline",
                        color: "success",
                        onClick: ($event: any) => (_ctx.$router.push({path: _ctx.$t('toolbar.path_tickets') + '/' + lecture.hashId }))
                      }, null, 8, ["onClick"]))
                    : (_ctx.basket.map(el => el.itemId).includes(_ctx.event.hashId))
                      ? (_openBlock(), _createBlock(_component_button_loading, {
                          key: 1,
                          size: "small",
                          text: 'V košíku',
                          fill: "outline",
                          class: _normalizeClass('basket'),
                          color: 'success',
                          icon: 'checkmarkOutline',
                          onClick: ($event: any) => (_ctx.purchase(lecture))
                        }, null, 8, ["onClick"]))
                      : (lecture.price == 0)
                        ? (_openBlock(), _createBlock(_component_button_loading, {
                            key: 2,
                            size: "small",
                            text: 'Otevřít',
                            fill: "outline",
                            class: _normalizeClass('basket'),
                            color: 'success',
                            icon: 'chevronForwardOutline',
                            slot: "end",
                            onClick: ($event: any) => (_ctx.$router.push({path: _ctx.$t('toolbar.path_tickets') + '/' + lecture.hashId }))
                          }, null, 8, ["onClick"]))
                        : (_openBlock(), _createBlock(_component_button_loading, {
                            key: 3,
                            size: "small",
                            text: lecture.price + ' Kč',
                            fill: "outline",
                            class: _normalizeClass(_ctx.basket.map(el => el.itemId).includes(lecture.hashId) && 'basket'),
                            color: _ctx.basket.map(el => el.itemId).includes(lecture.hashId) ? 'success' : 'danger',
                            icon: _ctx.basket.map(el => el.itemId).includes(lecture.hashId) ? 'cartOutline' : 'checkmarkOutline',
                            onClick: ($event: any) => (_ctx.purchase(lecture))
                          }, null, 8, ["text", "class", "color", "icon", "onClick"]))
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                (lecture.purchased)
                  ? (_openBlock(), _createBlock(_component_button_loading, {
                      key: 0,
                      text: "Zakoupeno",
                      size: "small",
                      icon: "checkmarkOutline",
                      color: "success",
                      onClick: ($event: any) => (_ctx.$router.push({path: _ctx.$t('toolbar.path_tickets') + '/' + lecture.hashId }))
                    }, null, 8, ["onClick"]))
                  : (_ctx.basket.map(el => el.itemId).includes(_ctx.event.hashId))
                    ? (_openBlock(), _createBlock(_component_button_loading, {
                        key: 1,
                        size: "small",
                        text: 'V košíku',
                        fill: "outline",
                        class: _normalizeClass('basket'),
                        color: 'success',
                        icon: 'checkmarkOutline'
                      }))
                    : (lecture.price == 0)
                      ? (_openBlock(), _createBlock(_component_button_loading, {
                          key: 2,
                          size: "small",
                          text: 'Otevřít',
                          fill: "outline",
                          class: _normalizeClass('basket'),
                          color: 'success',
                          icon: 'chevronForwardOutline',
                          slot: "end",
                          onClick: ($event: any) => (_ctx.$router.push({path: _ctx.$t('toolbar.path_tickets') + '/' + lecture.hashId }))
                        }, null, 8, ["onClick"]))
                      : (_openBlock(), _createBlock(_component_button_loading, {
                          key: 3,
                          size: "small",
                          text: lecture.price + ' Kč',
                          fill: "outline",
                          class: _normalizeClass(_ctx.basket.map(el => el.itemId).includes(lecture.hashId) && 'basket'),
                          color: _ctx.basket.map(el => el.itemId).includes(lecture.hashId) ? 'success' : 'danger',
                          icon: _ctx.basket.map(el => el.itemId).includes(lecture.hashId) ? 'checkmarkOutline' : 'cartOutline',
                          onClick: ($event: any) => (_ctx.purchase(lecture))
                        }, null, 8, ["text", "class", "color", "icon", "onClick"]))
              ])
            ]),
            _: 2
          }, 1032, ["lines"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}