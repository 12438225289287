
import { IonButton, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { 
  cartOutline,
  addOutline,
  trashBin,
  pencil,
  create,
  checkmarkOutline,
  chevronDownOutline,
  chevronForwardOutline,
  chevronForwardCircleOutline,
  cardOutline,
} from 'ionicons/icons';

export default defineComponent({
  name: 'ButtonLoading',
  props: {
    color: {
      type: String,
      default() {
        return 'medium'
      }
    },
    size: {
      type: String,
      default() {
        return 'medium'
      }
    },
    text: {
      type: String,
    },
    shape: {
      type: String,
    },
    fill: {
      type: String,
      default() {
        return 'solid'
      }
    },
    icon: {
      type: String,
    },
    slot: {
      type: String,
      default() {
        return 'start'
      }
    },
    isLoading: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  components: {
    IonButton,
    IonIcon
  },
  setup() {
    return {
      cartOutline,
      addOutline,
      trashBin,
      pencil,
      create,
      checkmarkOutline,
      chevronDownOutline,
      chevronForwardOutline,
      chevronForwardCircleOutline,
      cardOutline
    }
  },
  methods: { 
    getIcon(item) {
      let icons = {
        cartOutline: cartOutline,
        addOutline: addOutline,
        trashBin: trashBin,
        pencil: pencil,
        create: create,
        checkmarkOutline: checkmarkOutline,
        chevronDownOutline: chevronDownOutline,
        chevronForwardOutline: chevronForwardOutline,
        chevronForwardCircleOutline: chevronForwardCircleOutline,
        cardOutline: cardOutline
      };
      if (item in icons) return icons[item];
    }
  },
});
