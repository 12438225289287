
import { IonCard, IonCardHeader, IonCardSubtitle, modalController } from '@ionic/vue';
import { IonAccordionGroup, IonAccordion, IonItem, IonList, IonLabel, IonRow, IonInput, IonTextarea, IonCheckbox, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex'
import { ActionTypes } from '@/modules/events/business'
import { addOutline, informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, checkmark, close } from "ionicons/icons";
import DatePickerModal from './modal/DatePickerModal.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import LevelBadge from '@/modules/common/components/LevelBadgeComponent.vue'
import CourseStatus from '@/modules/common/components/CourseStatusComponent.vue'
import IconPicker from './IconPickerComponent.vue'
import { EventState } from '../../enums'

const __default__ = defineComponent({
  name: 'EditEvent',
  components: {
    IonCard,
    IonCardHeader,
    // IonAccordionGroup, 
    // IonAccordion, 
    IonItem, 
    IonList,
    // IonLabel,
    IonRow,
    IonInput,
    IonTextarea,
    // IconPicker,
    // IonCheckbox,
    ButtonLoading,
    LevelBadge,
    CourseStatus,
  },
  props: {
    event: {
      type: Object,
      default() {
          return {eventId: "", startDate: "", endDate: ""}
      }
    },
  },
  data() {
    return {
      edit: true,
      forceCloseTools: false,
      newEvent: {
        eventId: this.event.hashId ? this.event.hashId : undefined,
        title: this.event.title ? this.event.title : "",
        level: this.event.level ? this.event.level : "A1",
        price: this.event.price ? this.event.price : undefined,
        startDate:  this.event.startDate ? this.event.startDate : undefined,
        description:  this.event.description ? this.event.description : "",
        lectures: this.event.lectures?.length ? this.event.lectures.map(el => {return {"title": el.title, "description": el.description.replaceAll('<br>','\n'), "icon": el.icon, "hashId": el.hashId, "price": el.price}}) : [{title:"", description: "", icon: "", hashId: (Math.random() + 1).toString(36).substring(6)}],
        type: this.event.type ? this.event.type : EventState.PLAN_NOREG,
        background: this.event.background ? this.event.background : undefined,
        linkInfoPack: this.event.linkInfoPack,
        linkWhatsApp: this.event.linkWhatsApp,
        checkout: {
          pageId: this.event.pageId ? this.event.pageId : undefined,
          title: undefined,
          subtitle: undefined,
          disabled: undefined,
          items: [{
            itemId: undefined,
            item: undefined,
            priceId: undefined,
            price: undefined
          }]
        },
        hidden: this.event.hidden ? this.event.hidden : false,
      },
      isLoadingConfirm: false,
      isLoadingDelete: false,
      isLoadingEditLecture: false,
      ready: true,
      titleDanger: false,
      dateDanger: false,
      EventState
    }
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'IS_AUTHORIZED',
      auth: 'AUTH'
    }),
    ...mapGetters('checkout',{
      checkout: 'CHECKOUT'
    }),
     backgroundImageHeader() {
      return 'url(' + process.env.VUE_APP_ASSETS_URL + '/background/header-background-1.png)'
    },
    backgroundImage() {
      if (this.newEvent.background) {
        return 'url(' + process.env.VUE_APP_ASSETS_URL + '/icon/' + this.newEvent.background + '-pattern.png)'
      } else {
        return 'none'
      }
      // const eventId = this.event.eventId;
      // return `linear-gradient(180deg, rgba(0,0,0,0) 0, var(--background-color-light) 80px), url(/assets/background/card-bg-${eventId}.svg)`;
    },
  },
  methods: {
    ...mapActions('events', {
      registerToEvent: ActionTypes.registerToEvent,
      getEvents: ActionTypes.getEvents
    }),
    ...mapActions('checkout', {
      getCheckout: 'GET_CHECKOUT'
    }),
    ...mapActions('internal', {
      editEvent: 'EDIT_EVENT'
    }),
    ...mapActions({
      isAuthorized: 'IS_AUTHORIZED',
    }),
    getOrdinalNum(n: number) {
      return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    },
    parseDate(startDate, endDate) {
      if (startDate) {
        const formattedStartDate = new Date(startDate)
        const formattedEndDate = new Date(endDate)
        if (typeof formattedStartDate === 'object' && formattedStartDate !== null && 'toLocaleDateString' in formattedStartDate) {
          if (endDate) {
            const startMonth = formattedStartDate.toLocaleDateString("en-US", { month: 'short' })
            const startDay = this.getOrdinalNum(parseInt(formattedStartDate.toLocaleDateString("en-US", { day: 'numeric' }))) 
            const endMonth = formattedEndDate.toLocaleDateString("en-US", { month: 'short' })
            const endDay = this.getOrdinalNum(parseInt(formattedEndDate.toLocaleDateString("en-US", { day: 'numeric' })))
            const date = startMonth == endMonth ? startMonth + " " + startDay + " - " + endDay : startMonth + " " + startDay + " - " + endMonth + " " + endDay
            return date
          } else {
            const startMonth = formattedStartDate.toLocaleDateString("en-US", { month: 'short' })
            const startDay = this.getOrdinalNum(parseInt(formattedStartDate.toLocaleDateString("en-US", { day: 'numeric' }))) 
            const date = startMonth + " " + startDay
            return date
          }
        }
      }
    },
    async openDatePickerModal() {
      const modal = await modalController.create({
        component: DatePickerModal,
        cssClass: 'date-picker-modal'
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        var date = new Date(data); // some mock date
        var datetime = date.getTime();
        this.newEvent.startDate = datetime
        
        // this.message = `Hello, ${data}!`;
      }
    },
    closeTools() {
      this.forceCloseTools = true
      setTimeout(() => {
        this.forceCloseTools = false
      }, 10);
    },
    isInternalRole() {
      var token = this.auth.token
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload).role != 'user';
    },
    adjustTextareaHeight(e) {
      var el = this.$refs[e] as any
      if (el) {
        var height = el.scrollHeight
        el.style = 'height:'+height+'px;'
      }
    },
    adjustDetailsTextareaHeight(e) {
      var el = this.$refs[e] as any
      if (el) {
        el.forEach(element => {
          var height = element.children[1]?.scrollHeight
          element.children[1].style = 'height:'+height+'px;'
        });
      }
    },
    pickIcon(e) {
      this.newEvent.background = e.icon
    },
    async confirmEdit() {
      this.isLoadingConfirm = true
      if (this.ready) {
        var event = structuredClone(this.newEvent)
        console.log('this.newEvent', this.newEvent)
        this.titleDanger = false
        this.dateDanger = false
        if (event.title && event.description && event.lectures.every(obj => obj.price != undefined)) {
          event.lectures = event.lectures.filter(e => {return e.title})
          event.checkout.items = event.checkout.items.filter(e => {return e.item})
          event.checkout = event.checkout.items.length ? event.checkout : undefined
          await this.editEvent({token: this.auth.token, data: event})
          await this.getEvents(this.auth.token)
          setTimeout(() => {
            this.$emit('close')
          }, 1000)
        } else {
          this.titleDanger = true
          this.dateDanger = true
        }
        
      }
      this.isLoadingConfirm = false
    },
    async hideEvent() {
      this.isLoadingDelete = true
      if (this.ready) {
        var event = structuredClone(this.newEvent)
        event.hidden = true
        event.lectures = event.lectures.filter(e => {return e.title})
        event.checkout.items = event.checkout.items.filter(e => {return e.item})
        event.checkout = event.checkout.items.length ? event.checkout : undefined
        await this.editEvent({token: this.auth.token, data: event})
        await this.getEvents(this.auth.token)
        setTimeout(() => {
          this.$emit('close')
        }, 1000)
      }
      this.isLoadingDelete = false
    },
    async editLecture(hashId) {
      if (JSON.stringify(this.newEvent.lectures.map(el => el.hashId)) != JSON.stringify(this.event.lectures.map(el => el.hashId)) ) {
        this.isLoadingEditLecture = hashId
        this.confirmEdit()
        this.isLoadingEditLecture = false
      }
      this.$router.push({path: this.$t('toolbar.path_tickets') + '/' + hashId })
      
    },
    addContent() {
      this.newEvent.lectures.push({title:"", description: "", icon: "", hashId: (Math.random() + 1).toString(36).substring(6)})
    },
    removeContent(index) {
      this.newEvent.lectures.splice(index, 1)
    },
    updateLevel(level) {
      this.newEvent.level = level
    },
    updateHidden(hidden) {
      console.log('hidden', hidden)
      this.newEvent.hidden = hidden
    },
    formatDescription(val, i) {
      this.newEvent.lectures[i].description = val.replaceAll('<br>', '\n')
    }
  },
  setup() {
    return {
      informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline,
      checkmark, close, addOutline
    };
  },
  watch: {
    'newEvent.checkout.items': {
      handler(val, oldVal) {
        if (Object.keys(this.checkout).length) {
          if (this.newEvent.checkout && this.newEvent.checkout.items) {
            var items = this.newEvent.checkout.items
            if (items.length && items.slice(-1)[0].item) {
              items.push({itemId: undefined, item: undefined, priceId: undefined, price: undefined})
            } 
            this.newEvent.checkout.items = items  
          }
        }
        
      },
      deep: true
    },
    checkout() {
      if (this.checkout) {
        this.newEvent.checkout.pageId = this.checkout.pageId
        this.newEvent.checkout.title = this.checkout.title
        this.newEvent.checkout.subtitle = this.checkout.subtitle
        this.newEvent.checkout.disabled = this.checkout.disabled
        this.checkout.items.forEach((e, i) => {
          var item = {
            itemId: e.itemId,
            item: e.item,
            priceId: e.priceId,
            price: e.price
          }
          if (i == 0) {
            this.newEvent.checkout.items[0] = item
          } else {
            this.newEvent.checkout.items.push(item)
          }
        });
      }
    }
  },
  mounted() {
    setTimeout(() => {
      // this.adjustTextareaHeight('textareaDescription')
      // this.adjustDetailsTextareaHeight('textareaDetailsDescription')
       
    },10)
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "eecb01dc": (_ctx.backgroundImageHeader),
  "00d22025": (_ctx.backgroundImage)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__