
  import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonIcon, modalController} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { closeOutline } from 'ionicons/icons';
  import SignIn from '../SignInComponent.vue'

  export default defineComponent({
    name: 'DeleteAccountModal',
    props: {
        prefilledEmail: {
            type: String
        }
    },
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonButtons,
      IonIcon, 
      SignIn
    },
    setup() {
      return {
        closeOutline
      }
    },
    methods: {
      cancel() {
        return modalController.dismiss(null, 'cancel');
      },
      confirm() {
        return modalController.dismiss(this, 'confirm');
      },
    },
  });
