export enum Roles {
  USER = 'user',
  INTERNAL = 'internal',
  ADMIN = 'admin',
}

export enum Site {
  cl = 'Cabana Libre',
  bosc = 'Buena Onda Social Club',
}
