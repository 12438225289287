
  import { IonContent, IonHeader, IonTitle, IonToolbar, IonList, IonItem, IonSelect, IonSelectOption, IonText, modalController} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { mapGetters, mapActions } from 'vuex'
  import { GettersTypes, ActionTypes } from '../../../business'
  import { closeOutline } from 'ionicons/icons';
  import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue';

  export default defineComponent({
    name: 'AssignTicketModal',
    components: { 
      IonContent, 
      IonHeader, 
      IonTitle, 
      IonToolbar, 
      IonList, 
      IonItem,
      IonSelect,
      IonSelectOption,
      IonText,
      ButtonLoading
    },
    props: {
      email: {
        type: String
      }
    },
    setup() {
      return {
        closeOutline
      }
    },
    data() {
      return {
        event: undefined,
        page: undefined,
        item: undefined,
        isLoading: false,
        buttonColor: 'light'
      }
    },
    computed: {
    ...mapGetters('internal',{
      checkoutPages: GettersTypes.checkoutPages,
    }),
  },
    methods: {
      ...mapActions('internal',{
        getCheckoutPages: ActionTypes.getCheckoutPages,
        assignTicket: ActionTypes.assignTicket,
      }),
      cancel() {
        return modalController.dismiss(null, 'cancel');
      },
      async confirm() {
        this.isLoading = true
        var selectedEvent = this.event
        var selectedPage = this.page
        var selectedItem = this.item
        var tickets = this.checkoutPages.filter(function(e){return e.event == selectedEvent && e.page == selectedPage && selectedItem.includes(e.item)})
        tickets = tickets.map(obj => ({ ...obj, email: this.email }))
        await this.assignTicket({token: this.$cookies.get('access_token'), data: tickets})
        this.isLoading = false
        this.buttonColor = 'success'
        setTimeout(() => {
          return modalController.dismiss(this, 'confirm');
        }, 1000);
      },
      getEvents() {
        return [...new Set( this.checkoutPages.map(item => item.event))]
      },
      getPages(event) {
        var pages = this.checkoutPages.filter(function(e){return e.event == event})
        return [...new Set( pages.map(item => item.page))]
      },
      getItems(page) {
        var items = this.checkoutPages.filter(function(e){return e.page == page})
        return [...new Set( items.map(item => item.item))]
      }
    },
    mounted(){
      this.getCheckoutPages(this.$cookies.get('access_token'))
    },
    watch: {
      event() {
        this.page = undefined,
        this.item = undefined
      },
      page() {
        this.item = undefined
      },
    }
  });
