import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62c7e0f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-loader" }
const _hoisted_2 = {
  key: 0,
  class: "dot-carousel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    shape: _ctx.shape ? _ctx.shape : 'round',
    fill: _ctx.fill,
    size: _ctx.size,
    color: _ctx.color,
    mode: "md"
  }, {
    default: _withCtx(() => [
      (_ctx.icon && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 0,
            slot: _ctx.slot,
            icon: _ctx.getIcon(_ctx.icon)
          }, null, 8, ["slot", "icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", {
        style: _normalizeStyle(_ctx.isLoading ? 'opacity: 0' : '')
      }, _toDisplayString(_ctx.text), 5)
    ]),
    _: 1
  }, 8, ["shape", "fill", "size", "color"]))
}