
import { IonContent, IonPage, IonCard, IonCardTitle, IonCardSubtitle, IonText, IonRow, IonList, IonItem, IonAvatar, IonLabel} from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import EventCardComponent from '@/modules/events/presentation/components/EventCardComponent.vue'
import CoreValues from '../components/CoreValuesComponent.vue'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { logoInstagram, logoFacebook, logoSoundcloud, logoTwitch, logoWhatsapp } from "ionicons/icons";

export default defineComponent({
name: 'LegalPage',
  components: {
    IonContent,
    IonPage,
    IonText
  },
  // data() {
  //   return {
      
  //   }
  // },
  setup() {
    return {
      logoInstagram,
      logoFacebook,
      logoSoundcloud,
      logoTwitch,
      logoWhatsapp
    }
  }
});
