
import { IonItem, IonList, IonLabel, IonIcon } from '@ionic/vue'
import { defineComponent } from 'vue'
import { logoYoutube, bookOutline, headsetOutline, pencilOutline } from "ionicons/icons";

export default defineComponent({
name: 'IconPicker',
component: {
  IonItem, 
  IonList,
  IonLabel,
  IonIcon
},
props: {
  setIcon: {
    type: String
  },
  index: {
    type: Number
  },
  open: {
    type: Boolean
  }
},
setup() {
  return {
    logoYoutube,
    bookOutline,
    headsetOutline,
    pencilOutline,
  }
},
data() {
  return {
    toolIcon: this.setIcon?.length ? this.setIcon : 'menu-dots',
    icons: ['headsetOutline','logoYoutube','bookOutline','pencilOutline'],
  }
},
methods: {
  getIcon(item) {
    let icons = {
      logoYoutube: logoYoutube,
      bookOutline: bookOutline,
      headsetOutline: headsetOutline,
      pencilOutline: pencilOutline,
    };
    if (item in icons) return icons[item];
  },
  pickIcon(icon) {
    this.$emit('icon', icon)
  }
},

});
