import { MutationTree } from 'vuex'
import { TicketsState, ticketsState } from '@/modules/tickets/business/state'
import { Course, Content } from '../types'

export enum MutationTypes {
  courses = 'COURSES',
  content = 'CONTENT',
}
type RootState = ReturnType<typeof ticketsState>

export const mutations: MutationTree<RootState> = {
  [MutationTypes.courses](state: TicketsState, courses: [Course]) {
    state.courses = courses;
  },
  [MutationTypes.content](state: TicketsState, content: [Content]) {
    state.content = content;
  },
}
