import { MutationTypes } from './mutations'
import axios from 'axios'
import { ScanStatuses } from '../enums';
import { useToast } from "vue-toastification";

export enum ActionTypes {
    search = "SEARCH",
    selectTable = "SELECT_TABLE",
    scan = "SCAN",
    getRegistrations = "GET_REGISTRATIONS",
    getPayments = "GET_PAYMENTS",
    getUsers = "GET_USERS",
    getNewUsers = "GET_NEW_USERS",
    eventApproval = "EVENT_APPROVAL",
    getCheckoutPages = "GET_CHECKOUT_PAGES",
    assignTicket = "ASSIGN_TICKET",
    editEvent = 'EDIT_EVENT',
    editBlog = 'EDIT_BLOG',
    sendMessage = 'SEND_MESSAGE',
    editToggle = 'EDIT_TOGGLE'
}


export const actions = {
    async [ActionTypes.search]({ commit }, value) {
        commit(MutationTypes.search, value)
    },
    async [ActionTypes.selectTable]({ commit }, table) {
        commit(MutationTypes.table, table)
    },
    async [ActionTypes.scan]({ commit }, data) {
        const path = process.env.VUE_APP_BASE_URL+'/internal/scan/';
        await axios.post(path,data).then((response) => {
            if (response.status == 200) {
                commit(MutationTypes.scanData, response.data)
                commit(MutationTypes.scanStatus, ScanStatuses.SUCCESS)
                useToast().success("Ticket scanned!")
            } else {
                commit(MutationTypes.scanData, response.data)
                commit(MutationTypes.scanStatus, ScanStatuses.SCANNED)
                useToast().warning("Ticket is scanned already!")
            }
        }).catch((error) => {
            if (error.response.status == 400) {
                commit(MutationTypes.scanStatus, ScanStatuses.INVALID)
                useToast().error("Invalid ticket! Contact the admin.")
            } else {
                useToast().error("Something went wrong!\nRefresh the window and try again.")
            }
        })
    },
    async [ActionTypes.getRegistrations]({ commit }, token) {
        commit(MutationTypes.loading, true)
        const path = process.env.VUE_APP_BASE_URL+'/registrations/'+token;
        await axios.get(path).then((response) => {
            commit(MutationTypes.registrations, response.data)
            commit(MutationTypes.loading, false)
        })
    },
    async [ActionTypes.getPayments]({ commit }, token) {
        const path = process.env.VUE_APP_BASE_URL+'/payments/'+token;
        commit(MutationTypes.loading, true)
        await axios.get(path).then((response) => {
            commit(MutationTypes.payments, response.data)
            commit(MutationTypes.loading, false)
        })
    },
    async [ActionTypes.getUsers]({ commit }, token) {
        const path = process.env.VUE_APP_BASE_URL+'/users/'+token;
        commit(MutationTypes.loading, true)
        await axios.get(path).then((response) => {
            commit(MutationTypes.users, response.data)
            commit(MutationTypes.loading, false)
        })
    },
    async [ActionTypes.eventApproval]({ commit }, {token, data}) {
        const path = process.env.VUE_APP_BASE_URL+'/registration_approval/'+token;
        await axios.post(path,data).then((response) => {
            console.log('approved', data)
            useToast().success("User approved!")
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.")
        })
    },
    async [ActionTypes.getCheckoutPages]({ commit }, token) {
        const path = process.env.VUE_APP_BASE_URL+'/checkout_pages/'+token;
        await axios.get(path).then((response) => {
            commit(MutationTypes.checkoutPages, response.data)
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.")
        })
    },
    async [ActionTypes.assignTicket]({ commit }, {token, data}) {
        const path = process.env.VUE_APP_BASE_URL+'/assign_ticket/'+token;
        await axios.post(path,data).then((response) => {
            useToast().success("Ticket assigned!")
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.")
        })
    },
    async [ActionTypes.editEvent]({ commit }, {token, data}) {
        const path = process.env.VUE_APP_BASE_URL+'/internal/edit_event/'+token;
        await axios.post(path,data).then((response) => {
            useToast().success("Events updated!")
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.")
        })
    },
    async [ActionTypes.editBlog]({ commit }, {token, data}) {
        const path = process.env.VUE_APP_BASE_URL+'/internal/edit_blog/'+token;
        await axios.post(path,data).then((response) => {
            useToast().success("Blog updated!")
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.")
        })
    },
    async [ActionTypes.sendMessage]({ commit }, data ) {
        const path = process.env.VUE_APP_BASE_URL+'/send_message/';
        await axios.post(path,data).then((response) => {
            useToast().success("Zpráva odeslána!\nObratem se vám ozveme 😊")
        }).catch((error) => {
            useToast().error("Something went wrong!\nRefresh the window and try again.")
        })
    },
    async [ActionTypes.editToggle]({ commit }, data ) {
        commit(MutationTypes.editToggle, data)
    },
}
