
import { IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, IonRow, IonIcon, modalController, IonSkeletonText, toastController } from '@ionic/vue';
import { IonAccordionGroup, IonAccordion, IonList, IonItem, IonLabel, IonText, IonTitle } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex'
import { EventState, UserEventState } from '../../enums';
import { ActionTypes } from '@/modules/events/business'
import { informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, pencil, chevronForwardOutline, chevronDownOutline, cartOutline } from "ionicons/icons";
import OpenRegistrationModal from './modal/OpenRegistrationModal.vue'
import CheckoutPageModal from './modal/CheckoutPageModal.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import LevelBadge from '@/modules/common/components/LevelBadgeComponent.vue'
import LecturesList from '@/modules/events/presentation/components/LecturesListComponent.vue'

const __default__ = defineComponent({
  name: 'EventCard',
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    // IonCardSubtitle,
    // IonRow,
    // IonIcon,
    // IonList,
    // IonItem, 
    // IonLabel, 
    // IonText, 
    // IonTitle,
    IonSkeletonText,
    ButtonLoading,
    LevelBadge,
    // IonAccordionGroup, 
    // IonAccordion
    LecturesList
  },
  props: {
    event: {
        type: Object,
        default() {
            return {eventId: "0", startDate: "", endDate: "", details: []}
        }
    },
    loading: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      loaderShown: false,
      UserEventState, 
      EventState,
      infoShown: false,
      showSkeleton: true
    }
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'IS_AUTHORIZED',
      auth: 'AUTH'
    }),
    ...mapGetters('checkout',{
      basket: 'BASKET'
    }),
    backgroundImageHeader() {
      return 'url(' + process.env.VUE_APP_ASSETS_URL + '/background/header-background-1.png)'
    },
    backgroundImage() {
      return 'url(' + process.env.VUE_APP_ASSETS_URL + '/icon/' + this.event?.background + '-pattern.png)'
      
    },
  },
  methods: {
    ...mapActions('events', {
      registerToEvent: ActionTypes.registerToEvent
    }),
    ...mapActions({
      isAuthorized: 'IS_AUTHORIZED',
    }),
     ...mapActions('checkout', {
      setBasket: 'SET_BASKET'
    }),
    async openCheckoutModal() {
      const modal = await modalController.create({
        component: CheckoutPageModal,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        // this.message = `Hello, ${data}!`;
      }
    },
    getIcon(name) {
      let icons = {
        'informationCircleOutline': informationCircleOutline, 
        'pinOutline': pinOutline,
        'busOutline': busOutline,
        'flowerOutline': flowerOutline,
        'musicalNotesOutline': musicalNotesOutline,
        'shirtOutline': shirtOutline,
        'chevronForwardOutline': chevronForwardOutline,
      };

      if (name in icons) return icons[name];
    },
    triggerCollapse() {
      setTimeout(() => this.infoShown = !this.infoShown, 200);
    },
    addToCalendar(title) {
      window.open('/assets/calendar/' + title + '.ics')
    },
    isInternalRole() {
      var token = this.auth.token
      if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload).role != 'user';
      }
      return false
    },
    purchase(item) {
      const index = this.basket.findIndex(basket => basket.itemId == item.hashId);
      if (index == -1) {
        console.log(this.event.lectures.map(el => el.hashId))
        this.event.lectures.map(el => el.hashId)?.forEach(hashId => {
          const index = this.basket.findIndex(basket => basket.itemId == hashId);
          // console.log(index)
          this.basket.splice(index, 1)
        });
        this.basket.push({itemId: item.hashId, price: item.price})
        this.setBasket(this.basket)
        this.presentToastBasket()
      } else {
        this.basket.splice(index, 1)
        this.setBasket(this.basket)
      }
      
    },
    async presentToastBasket() {
      var basketToast = await toastController.create({
        header: 'Přidáno do košíku ✅',
        message: 'Klikněte pro zobrazení objednávky.',
        buttons: [
          {
            text: 'Košík',
            role: 'submit',
            icon: cartOutline,
            handler: () => {
              this.isAuthorized ? this.$router.push({path: this.$t('toolbar.path_checkout')}) : this.$router.push({path: this.$t('toolbar.path_signup')})
            }
          }
        ],
      });

      
      basketToast.present();
      setInterval(() => {
        basketToast.dismiss();
      }, 2000);
    },
  },
  setup() {
    return {
      informationCircleOutline, pinOutline, busOutline, flowerOutline, musicalNotesOutline, shirtOutline, ticketOutline, calendarOutline, pencil, chevronForwardOutline, chevronDownOutline, cartOutline
    };
  },
  watch: {
    event() {
      this.showSkeleton = false
    },
  },
  mounted() {
    this.showSkeleton = this.loading
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2297ffc6": (_ctx.backgroundImageHeader),
  "445b37d9": (_ctx.backgroundImage)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__