import { MutationTree } from 'vuex'
import { state } from './state'


export enum MutationTypes {
  
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {

}
