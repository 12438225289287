
import { IonIcon, IonInput } from '@ionic/vue'
import { pencil, checkmark, close, refresh } from "ionicons/icons";
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { GettersTypes, ActionTypes } from '../../../business'
import ToolButton from '../ToolButtonComponent.vue'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


export default defineComponent({
name: 'UsersTable',
  components: {
    // IonBadge,
    Vue3EasyDataTable,
    ToolButton,
    IonIcon,
    IonInput,
  },
  setup() {
    return {
      pencil,
      checkmark,
      close,
      refresh
    }
  },
  computed: {
    ...mapGetters('internal',{
      search: GettersTypes.search,
      users: GettersTypes.users,
      loading: GettersTypes.loading,
    }),
  },
  methods: {
    ...mapActions('internal',{
      getUsers: ActionTypes.getUsers,
    }),
    ...mapActions({
      updateAccountInfo: 'UPDATE_ACCOUNT_INFO',
    }),
    formattedTime(time) {
      var date = new Date(time)
      var formattedTime = date.toLocaleTimeString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) 
      return formattedTime
    },
    closeTools() {
      this.forceCloseTools = true
      setTimeout(() => {
        this.forceCloseTools = false
      }, 10);
    },
    editDetails(item) {
      var index = this.data.findIndex(function(e) {return e.id == parseInt(item.id)})
      this.data[index].edit = !this.data[index].edit
      item.editDetails.name = item.name
      item.editDetails.surname = item.surname
      item.editDetails.street = item.street
      item.editDetails.city = item.city
      item.editDetails.postalCode = item.postalCode
    },
    async updateDetails(item) {
      var index = this.users.findIndex(function(e) {return e.id == parseInt(item.id)})
      this.users[index].editLoading = true
      await this.updateAccountInfo({ 
        email: item.email,
        name: item.editDetails.name ? item.editDetails.name : item.name,
        surname: item.editDetails.surname ? item.editDetails.surname : item.surname,
        street: item.editDetails.street ? item.editDetails.street : item.street,
        city: item.editDetails.city ? item.editDetails.city : item.city,
        postalCode: item.editDetails.postalCode ? item.editDetails.postalCode : item.postalCode 
      })
      this.users[index].name = item.editDetails.name
      this.users[index].surname = item.editDetails.surname
      this.users[index].street = item.editDetails.street
      this.users[index].city = item.editDetails.city
      this.users[index].postalCode = item.editDetails.postalCode
      this.users[index].edit = false
      this.users[index].editLoading = false
      
    },
    async refreshTable() {
      this.refreshLoading = true
      await this.getUsers(this.$cookies.get('access_token'))
      var ids = this.data.map(a => a.id)
      var newData = this.users.filter(e => !ids.includes(e.id))
      if (newData.length) {
        newData.forEach(e => e.new = 'true');
        this.data = newData.concat(this.data)
      }
      this.refreshLoading = false
    }
  },
  data() {
    return {
      headers: [
        { text: "", value: "new"},
        { text: "DATUM VYTVOŘENÍ", value: "creation_time", sortable: true},
        { text: "EMAIL", value: "email", sortable: true},
        { text: "JMÉNO", value: "name", sortable: true},
        { text: "PŘÍJMENÍ", value: "surname", sortable: true},
        { text: "ULICE", value: "street", sortable: true},
        { text: "MĚSTO", value: "city", sortable: true},
        { text: "PSČ", value: "postalCode", sortable: true},
        { text: "", value: "tools"},
      ],
      searchValue: ref(""),
      forceCloseTools: false,
      data: [],
      refreshLoading: false
    }
  },
  async mounted() {
    await this.getUsers(this.$cookies.get('access_token'))
    this.data = this.users
  }
})
