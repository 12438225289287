<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { IonPage, IonContent } from '@ionic/vue'
export default {
  name: 'StripePage',
  components: {
    IonPage,
    IonContent
  },
  data () {
    return {
      loading: false,
      // successURL: process.env.VUE_APP_FE_URL + (this.payload.token ? this.$t('toolbar.path_checkout')+'?id=1' + '&paymentId='+this.payload.paymentHash : this.$t('toolbar.path_tickets') + '?paymentId='+this.payload.paymentHash),
      // cancelURL: process.env.VUE_APP_FE_URL + (this.payload.token ? this.$t('toolbar.path_checkout')+'?id=1' : this.$t('toolbar.path_events'))
    };
  },
  mounted() {
    setTimeout(function() {
      window.top.location = process.env.VUE_APP_FE_URL + '/lekce'
    }, 2000)
  },
};
</script>

<template>
  <ion-page>
    <ion-content style="text-align: center;" @click="window.top.location = process.env.VUE_APP_FE_URL + '/lekce'">
        <h2>ALLES KLAR!</h2><br>
        <img src="/assets/sites/tak/animations/payment-confirmed.svg" style="width: 100%; max-width: 400px;" />
    </ion-content>
  </ion-page>
</template>