import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c7da1fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "add-icon"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_blog_post = _resolveComponent("blog-post")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (['internal', 'admin'].includes(_ctx.auth.role))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.addCircleOutline,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addNewPost = true))
                }, null, 8, ["icon"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              (_ctx.addNewPost)
                ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_blog_post, {
                        add: true,
                        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNewPost = false)),
                        onConfirm: _cache[2] || (_cache[2] = ($event: any) => (_ctx.refreshBlog())),
                        class: "add-post"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!this.posts)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (post) => {
                      return _createVNode(_component_ion_item, { key: post }, {
                        default: _withCtx(() => [
                          _createVNode(_component_blog_post, {
                            post: this.blog[0],
                            loading: true
                          }, null, 8, ["post"])
                        ]),
                        _: 2
                      }, 1024)
                    }), 64))
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.posts, (post) => {
                return (_openBlock(), _createBlock(_component_ion_item, { key: post }, {
                  default: _withCtx(() => [
                    _createVNode(_component_blog_post, {
                      post: post,
                      onConfirm: _cache[3] || (_cache[3] = ($event: any) => (_ctx.refreshBlog()))
                    }, null, 8, ["post"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadData($event))),
            threshold: "100px",
            id: "infinite-scroll",
            disabled: !this.available,
            position: "bottom"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, {
                "loading-spinner": "none",
                "loading-text": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_blog_post, {
                        post: this.blog[0],
                        loading: true
                      }, null, 8, ["post"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}