
import { IonCard, IonCardTitle, IonCardSubtitle, IonText, IonInput, IonTextarea, IonIcon, IonAvatar, IonCardHeader, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';
import { informationCircleOutline, logoWhatsapp, logoInstagram, pencil } from 'ionicons/icons';
import { mapGetters, mapActions } from 'vuex'
import { BlogTypes } from '@/modules/blog/enums'
import UploadAvatar from './UploadAvatarComponent.vue'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'
import LevelBadge from '@/modules/common/components/LevelBadgeComponent.vue'
import IconBadge from '@/modules/common/components/IconBadgeComponent.vue'
import axios from 'axios'
import ExpandableText from '@/modules/blog/presentation/components/ExpandableTextComponent.vue';

export default defineComponent({
  name: 'BlogPost',
  components: {
    IonCard,    
    IonCardTitle,
    // IonCardSubtitle,
    IonCardHeader,
    IonCardContent,
    // IonText,
    // IonInput,
    // IonTextarea,
    // IonIcon,
    IonAvatar,
    ButtonLoading,
    UploadAvatar,
    LevelBadge,
    IconBadge,
    ExpandableText
  },
  props: {
    post: {
      type: Object,
      default() {
        return {
          id: undefined,
          creationTime: "",
          title: "",
          content: "",
          additionalContent: "",
          type: "podcast",
          level: "A1",
          postedBy: ""    
        }
      }
    },
    loading: {
      type: Boolean
    },
    add: {
      type: Boolean
    }
  },
  data() {
    return {
      edit: this.add,
      editPost: {
        id: this.post?.id,
        title: this.post?.title,
        content: this.post?.content,
        additionalContent: this.post?.additionalContent,
        type: this.post?.type,
        level: this.post?.level,
        postedBy: this.post?.postedBy
      },
      showSkeleton: this.loading,
      isLoadingConfirm: false,
      isLoadingDelete: false,
      uploadImage: false,
      spotifyFix: this.post?.additionalContent?.replace('width="100%" height="352"', 'width="100%" height="100%"'),
      BlogTypes
    }
  },
  computed: {
    ...mapGetters({
      auth: 'AUTH',
      accountInfo: 'ACCOUNT_INFO'
    }),
    getAvatar() {
      return process.env.VUE_APP_BASE_URL + '/get_file/team/' + (this.add ? this.auth.email : this.post.postedBy) + '.jpg'
    },
    getImage() {
      return process.env.VUE_APP_BASE_URL + '/get_file/blog/' + this.post.id + '.png' + '?v=' + new Date().getTime()
    }
  },
  methods: {
    ...mapActions({
      getAccountIfo: 'GET_ACCOUNT_INFO'
    }),
     ...mapActions('internal', {
      editBlog: 'EDIT_BLOG'
    }),
    ...mapActions('blog',{
      getBlogBatch: 'GET_BLOG_BATCH'
    }),
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      date = new Date(date)
      const months = ['Ledna','Února','Března','Dubna','Května','Června','Červenece','Srpna','Září','Října','Listopadu','Prosince']
      return date.getDate() + '. ' + months[date.getMonth()] + ' ' + date.getFullYear()
      
    },
    async confirmEdit() {
      this.isLoadingConfirm = true
      var event = structuredClone(this.editPost)
      this.titleDanger = false
      this.dateDanger = false
      if (event.title && event.content) {
        await this.editBlog({token: this.auth.token, data: event})
        this.isLoadingConfirm = false
        this.edit = false
      } else {
        this.titleDanger = true
        this.dateDanger = true
      }
      this.uploadImage = true
    },
    async hideEvent() {
      this.isLoadingDelete = true
      var event = structuredClone(this.editPost)
      event.hidden = true
      await this.editBlog({token: this.auth.token, data: event})
      this.isLoadingDelete = false
      this.$emit('confirm')
    },
    updateType(e) {
      this.editPost.type = e
    },
    updateLevel(e) {
      this.editPost.level = e
    },
    adjustTextareaHeight(e) {
      var el = this.$refs[e] as any
      if (el && el.value.length) {
        var height = el.scrollHeight
        el.style = 'height:'+height+'px;'
      } else if (el) {
        el.style = 'height: 47px;'
      }
    },
  },
  setup() {
    return {
      informationCircleOutline,
      logoWhatsapp,
      logoInstagram,
      pencil
    }
  },
  mounted() {
    if (!this.edit) {
      this.showSkeleton = true
    }
    if (this.edit && !this.accountInfo.name) {
      this.getAccountIfo(this.$cookies.get('access_token'))
    }
    if (this.post.id) {
      axios.get(this.getImage).then((response) => {
      if (!this.loading) {
        this.showSkeleton = false
      }
    });
    }
  },
  watch: {
    edit() {
      setTimeout(() => {
        this.adjustTextareaHeight('textareaTitle')
        this.adjustTextareaHeight('textareaDescription')
      },10)
    },
    'editPost.title': {
      handler(val, oldVal) {
        this.adjustTextareaHeight('textareaTitle')
      },
      deep: true
    },
    'editPost.content': {
      handler(val, oldVal) {
        this.adjustTextareaHeight('textareaDescription')
      },
      deep: true
    },
  },
});
