
export enum Level {
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1',
    C2 = 'C2'
}

export enum LevelColor {
    A1 = '--ion-color-level-one',
    A2 = '--ion-color-level-two',
    B1 = '--ion-color-level-three',
    B2 = '--ion-color-level-four',
    C1 = '--ion-color-level-five',
    C2 = '--ion-color-level-six'
}

export enum TypeColor {
    podcast = 'dark',
    article = 'light',
    edit = 'warning',
}

export enum TypeIcon {
    podcast = 'headset',
    article = 'book',
    edit = 'pencil',
}
