import { Event } from '../types'

export type State = {
  events: [Event]
};

export const state = (): State => ({
  events: [{
    hashId: "",
    title: "",
    description: "",
    lectures: [
      {
        title: "",
        description: "",
        price: 0,
        hashId: "",
        progress: 0,
        purchased: 0
      }
    ],
    edit: false,
  }]
})
