
    import {
        IonContent,
        IonDatetime,
        // IonButton,
        modalController,
    } from '@ionic/vue';
    import { defineComponent, ref } from 'vue';
    export default defineComponent({
        name: 'DatePickerModal',
        components: { IonContent, IonDatetime },
        data() {
          return {
            setDatetime: new Date().toISOString().slice(0,11) + '18:00:00.000Z'
          }
        },
        setup() {
          const datetime = ref();
          const cancel = () => datetime.value.$el.cancel();
          const confirm = () => datetime.value.$el.confirm();
          return { datetime, cancel, confirm }
        },
        methods: {
          close() {
            this.$emit('setDatetime',this.setDatetime)
            return modalController.dismiss(this.setDatetime, 'confirm');
          },
        },
    });
