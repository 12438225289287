import { Course, Content } from '../types'

export type TicketsState = {
  courses?: [Course],
  content?: [Content]
};

export const ticketsState = (): TicketsState => ({
 
})
