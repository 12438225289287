import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_icon, {
      icon: _ctx.ellipsisVertical,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (this.openTools()))
    }, null, 8, ["icon"]),
    _withDirectives(_createVNode(_component_ion_list, { lines: "none" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (this.openAssignTicketModal(_ctx.item.email)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              innerHTML: _ctx.$t('internal.assign_ticket')
            }, null, 8, ["innerHTML"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, {
              innerHTML: _ctx.$t('internal.block_user'),
              color: "danger"
            }, null, 8, ["innerHTML"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, this.open]
    ])
  ], 64))
}