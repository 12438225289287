import { Event } from '@/modules/events/types'
import { UserEventState } from '@/modules/events/enums'


export type State = {
  events?: [Event],
};

export const state = (): State => ({
  
})
