
  import {  IonButton,
            IonIcon,
            IonInput,
            IonItem,
            IonLabel,
            IonToggle } from '@ionic/vue';
  import { searchOutline, closeOutline } from 'ionicons/icons';
  import { defineComponent } from 'vue';
  import { mapGetters, mapActions } from 'vuex'
  import { Tables } from '@/modules/internal/enums'
  import { Roles } from '@/modules/auth/enums'
  
  export default defineComponent({
    name: 'InternalNavigation',
    components: {
      IonButton,
      IonIcon,
      IonInput,
      IonItem,
      IonLabel,
      IonToggle
    },
    props: {
      isAuthorized: {
        type: Boolean
      },
      forceClose: {
        type: Boolean
      }
    },
    data() {
      return {
        isMenuOpen: false,
        activeMenuColor: 'primary',
        activated: false,
        search: "",
        expandSearchPhone: false,
        isEdit: false,
        showEdit: false,
        Tables
      }
    },
    setup() {
      return {
        searchOutline,
        closeOutline
      }
    },
    computed: {
      ...mapGetters({
        isAuthorized: 'IS_AUTHORIZED',
        auth: 'AUTH'
      }),
      ...mapGetters('internal',{
        table: 'TABLE'
      })
    },
    methods: {
      ...mapActions('internal',{
        setSearch: 'SEARCH',
        selectTable: 'SELECT_TABLE',
        editToggle: 'EDIT_TOGGLE'
      }),
      click() {
        this.isMenuOpen = !this.isMenuOpen
        this.$emit('fabClick')
      },
      isCurrentPath(path) {
        return path.split('/')[1] == this.$router.currentRoute.value.path.split('/')[1]
      },
      navigateTable(table) {
        this.$router.push({query: {name: table}})
        this.selectTable(table)
      },
      clearSearch() {
        this.search = "",
        this.expandSearchPhone = false
      },
      toggleEdit(event) {
        this.editToggle(event.detail.checked)
        this.isEdit = event.detail.checked
      },
      isInternalRole() {
        return (this.auth.role == Roles.ADMIN || this.auth.role == Roles.INTERNAL)
      }
    },
    watch: {
      search() {
        this.setSearch(this.search)
      },
      '$route' (to, from){
        this.editToggle(false)
        this.isEdit = false
        this.showEdit = this.isCurrentPath(this.$t('toolbar.path_tickets')) && this.isInternalRole()
      },
      isEdit() {
        this.editToggle(this.isEdit)
      },
      'auth.role'() {
        this.showEdit = this.isCurrentPath(this.$t('toolbar.path_tickets')) && this.isInternalRole()
      }
    },
    mounted() {
      this.showEdit = this.isCurrentPath(this.$t('toolbar.path_tickets')) && this.isInternalRole()
    }
  });
  