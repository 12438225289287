<script>
import { IonPage, IonContent, IonList, IonItem, IonLabel, IonText, IonTitle, IonLoading } from '@ionic/vue'
import { defineComponent } from 'vue';
import { addOutline, logoYoutube, bookOutline, headsetOutline, pencilOutline, chevronForwardOutline } from 'ionicons/icons';
import { mapGetters, mapActions } from 'vuex'
import { ActionTypes, GettersTypes } from '@/modules/tickets/business'
import ButtonLoading from '@/modules/common/components/ButtonLoadingComponent.vue'

export default defineComponent({
  name: 'StepProgressBar',
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem, 
    IonLabel,
    IonText, 
    IonTitle,
    IonLoading,
    // ButtonLoading
  },
  data() {
    return {
      // courses: [
      //   {
      //     title: "Základy gramatiky 1",
      //     description: "Vhodné pro úplné i věčné začátečníky, kteří se chtějí naučit německy rychle a hravě. Lekce obsahují pouze nutné základy, nebudu vás trápit nekonečnými seznamy výjimek ani zbytečnou teorií.",
      //     lectures: [
      //       {
      //         title: "Osobní zájmena a slovesa haben/sein",
      //         hashId: "xB56ja",
      //         progress: 0
      //       }
      //     ]
      //   }
      // ],
    }
  },
  computed: {
    ...mapGetters('tickets', {
      courses: GettersTypes.courses
    })
  },
  methods: {
    ...mapActions('tickets',{
      getLectures: ActionTypes.getLectures
    }),
    getIcon(item) {
      let icons = {
        addOutline: addOutline,
        logoYoutube: logoYoutube,
        bookOutline: bookOutline,
        headsetOutline: headsetOutline,
        pencilOutline: pencilOutline,
      };
      if (item in icons) return icons[item];
    },
  },
  setup() {
    return {
      addOutline,
      logoYoutube,
      bookOutline, 
      headsetOutline, 
      pencilOutline,
      chevronForwardOutline
    }
  },
  mounted() {
    this.getLectures(this.$cookies.get('access_token'))
  }
});
</script>

<template>
  <ion-page>
    <ion-content >
      <ion-loading
        :is-open="this.courses == undefined"
        spinner="circular"
      ></ion-loading>
       <div class="wrapper" v-for="course in courses" :key="course" style="margin-top: 50px; text-align: center;">
          <ion-title style="margin: 0 20px 0px 20px;" v-html="course.title"></ion-title>
          <!-- <ion-subtitle style="margin: 0 20px 10px 20px;" v-html="course.description"></ion-subtitle> -->
          <ion-list lines="inset">
            <ion-item class="detail" mode="ios" v-for="lecture in course.lectures" :key="lecture" button @click="$router.push({path: $t('toolbar.path_tickets') + '/' + lecture.hashId })">
              <div class="lecture">
                <ion-label class="ion-text-wrap">{{ lecture.title }}</ion-label>
                <ion-text v-html="lecture.description"></ion-text>
              </div>
              <!-- <div style="margin-right: 20px; color: var(--ion-color-dark-shade)">
                {{lecture.progress * 100}}%
              </div> -->
            </ion-item>
          </ion-list>
          <ion-button
            class="medium"
            style="margin: 50px auto;"
            color="none"
            shape="round"
            mode="ios"
            size="medium"
            @click="$router.push({path: $t('toolbar.path_events')})"
          >
            <ion-icon slot="end" :icon="chevronForwardOutline" style="margin-left: 5px;"></ion-icon>
            Více lekcí
          </ion-button>
        </div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.wrapper {
  margin: auto;
  padding: 10px 10%;
  max-width: 1200px;
  /*background: var(--ion-color-primary-shade);*/
  border-radius: 0;
}
.lecture {
  margin: 20px 0px 20px 0px; 
  width: 100%;
}

.ion-text-wrap {
  font-size: 18px;
  margin-bottom: 10px;
}

.ion-item * {
  padding-inline-start: 0
}

@media (max-width: 780px) { 
  .wrapper {
    padding: 10px 0px;
  }

  .lecture {
    margin: 20px 0px 20px 0px; 
    width: calc(100% - 30px);
  }
}
</style>
