
<script>
import FileUpload from 'vue-upload-component'
import { useToast } from "vue-toastification";
export default {
  components: {
    FileUpload,
  },
  props: {
    image: {
      type: String,
      default() {
        return 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
      }
    },
    name: {
      type: String
    },
    upload: {
      type: Boolean
    }
  },
  data() {
    return {
      files: [],
      postURL: process.env.VUE_APP_BASE_URL 
      + '/upload_file/' 
      + this.$router.currentRoute.value.path.replace('/', '')
      + '/' + this.name
      + '/' +  this.$cookies.get('access_token')
    }
  },
  computed:{
    computedBgImage() {
      return 'url(' + (this.files.length ? this.files.slice(-1)[0].url : this.image) + ')'
    }
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
        if (!/\.(png)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
  },
  watch: {
    upload() {
      if (!this.files.length) {
        this.$emit('success')
      }
      if (this.upload) {
        this.$refs.upload.active = true
      }
    },
    'files': {
      handler(val, oldVal) {
        if (this.files.slice(-1)[0].success) {
          this.$emit('success')
        } else if (this.files.slice(-1)[0].error) {
          useToast().error('Uplaod image failed! Please, try again.')
          this.$emit('error')
        }
      },
      deep: true
    },
  }
}
</script>
<template>
  <div class="drag" :style="{backgroundImage: computedBgImage}">
    <div class="upload">
      <div class="drop-container">
        <!-- <img :src="files.length ? files.slice(-1)[0].url : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"  class="rounded-circle" /> -->
        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
          <h3>Drag & Drop</h3>
        </div>  
      </div>
      <file-upload
        class="btn btn-primary"
        :post-action="this.postURL"
        :multiple="true"
        :drop="true"
        :drop-directory="true"
        @input-filter="inputFilter"
        v-model="files"
        ref="upload">
        <h3>Click<br>or<br>Drag & Drop</h3>
      </file-upload>
      <!-- <button type="button" class="upload" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="uploadFile()">
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Start Upload
        </button> -->
    </div>
  </div>
</template>
<style>

.rounded-circle {
  margin: auto;
  height: 100%;
  width: 100%;
   object-fit: cover;
}

.btn {
  opacity: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  background-color: black;
  transition: 0.2s;
}


.btn:hover {
  opacity: 0.6
}

.drag {
  background-size: cover;
  background-position: center;
}

.drag label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}

.btn {
  position: absolute;
  z-index: 100;
}


.drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  width: 250px;
  height: 250px;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

h3 {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  font-size: 32px;
  color: #fff;
  padding: 0;
}
</style>