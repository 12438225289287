import { MutationTree } from 'vuex'
import { State, state } from './state'
import { Blog } from '../types'

export enum MutationTypes {
    getBlog = 'GET_BLOG',
    available = 'AVAILABLE'
}
type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
    [MutationTypes.getBlog](state: State, blog: [Blog]) {
        state.blog = blog;
    },
    [MutationTypes.available](state: State, available: boolean) {
        state.available = available;
    },
}
