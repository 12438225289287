export enum ScanStatuses {
  SUCCESS = 'SUCCESS',
  SCANNED = 'SCANNED',
  INVALID = 'INVALID',
  NONE = 'NONE'
}

export enum Tables {
  USERS = 'users',
  REGISTRATIONS = 'registrations',
  PAYMENTS = 'payments'
}